import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { ProdHeadCoreSchema } from '@core/schemas/db/schema.db.prod';
import { PK } from '@core/types/types.pk';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { z } from 'zod';
import { IsoDateSchema } from '../schema.util';
import { CostComponent } from '@core/types/types.costComponent';
import { ElementRateSource } from '@core/types/types.elements';

/**
 * DBProdHeadElem
 */
export type DBProdHeadElem = z.infer<typeof DBProdHeadElemSchema>;
export const DBProdHeadElemSchema = ProdHeadCoreSchema.omit({
  changed_by: true,
  changed_timestamp: true,
}).extend({
  PK: z.literal(PK.ProdHeadElem),
  bom_status: z
    .number()
    .int()
    .describe(createDescription({ supportLov: true })),
});

/**
 * DBElemCost
 */
export type DBElemCost = z.infer<typeof DBElemCostSchema>;
export const DBElemCostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ElemCost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  material: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  cost_comp: z
    .enum([
      CostComponent.LEGOElements,
      CostComponent.PurchasedElements,
      CostComponent.OverdoseElements,
    ])
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  quantity: z.number(),
  total_cost: z.number(),
  uom: z.string().describe(createDescription({ supportLov: true })),
  selected_cost: z.number(),
  selected_cost_source: z
    .nativeEnum(ElementRateSource)
    .or(z.literal(''))
    .describe(createDescription({ supportLov: true })),
  selected_cost_quantity: z.number().int(),
  price_unit: z.number().int(),
  valid_from_timestamp: IsoDateSchema.optional(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBElemError
 */
export type DBElemError = z.infer<typeof DBElemErrorSchema>;
export const DBElemErrorSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ElemError),
  product_id: z.number().describe(createDescription({ isPartOfKey: true })),
  cost_comp: z.enum([
    CostComponent.LEGOElements,
    CostComponent.PurchasedElements,
    CostComponent.OverdoseElements,
  ]),
  revision: z.number().describe(createDescription({ isPartOfKey: true })),
  material: z.number().describe(createDescription({ isPartOfKey: true })),
  message: z.string(),
});

/**
 * DBBOM
 */
export enum BOMBOMType {
  Development = 'DEVELOPMENT',
  Model = 'MODEL',
}
export type DBBOM = z.infer<typeof DBBOMSchema>;
export const DBBOMSchema = DBRecordSchema.extend({
  PK: z.literal(PK.BOM),
  material_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  bom_number: z.number().int(),
  alternative: z.number().int(),
  bom_type: z.nativeEnum(BOMBOMType).describe(createDescription({ supportLov: true })),
  base_uom: z.string().describe(createDescription({ supportLov: true })),
  materials: z.string(), // JSON.stringify([[materialId,quantity,version], [materialId,quantity,version], [materialId,quantity,version], ...]) - DBBOMMaterial[]
  status: z
    .number()
    .int()
    .describe(createDescription({ supportLov: true })),
  timestamp: IsoDateSchema,
});

//                                  id      qty     version
export type DBBOMMaterialCompact = [number, number, string];
export type DBBOMMaterialObject = { materialId: number; quantity: number; version: string };
