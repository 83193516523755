import { PackSource, PackagingPackingKey } from '@core/types/types.packaging';
import { Calculator, useCacheOrFetchQuery } from '../calculator';
import { Calculate_FetchQuery } from '../calculator.types';
import { DBPackagingRate } from '@core/schemas/db/schema.db.packaging';
import { PK } from '@core/types/types.pk';
import { Region } from '@core/schemas/schema.common';

export async function calculate_packaging_standardCost_GetRows(props: {
  input: {
    year: number;
    packagingSize: number;
    sourceEu: PackSource;
    sourceUs: PackSource;
    packingRegionEu: Region;
    packingRegionUs: Region;
    salesRegionEu: Region;
    salesRegionUs: Region;
  };
  cache?: { packagingRates?: DBPackagingRate[] };
  query?: { query?: Calculate_FetchQuery };
}): Promise<{ key: PackagingPackingKey; fmc1quantity: number; fmc2quantity: number }[]> {
  const { input, cache, query } = props;

  const packagingRates = await useCacheOrFetchQuery({
    cachedItems: cache?.packagingRates,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackagingRate,
      query: Calculator.Pack.Packaging.StandardCost.GetRows.Filter.PackagingRates({
        year: input.year,
        packagingSize: input.packagingSize,
      }),
    },
    dataTypeDescription: 'packaging rates',
  });

  const packagingPackingKeys = Array.from(
    new Set(
      packagingRates
        .filter((r) => r.source === input.sourceEu || r.source === input.sourceUs)
        .map((r) => r.packing_key),
    ),
  );

  const inputs = packagingPackingKeys.map((key) => {
    const fmc1rate = packagingRates.find(
      (r) =>
        r.source === input.sourceEu &&
        r.packing_key === key &&
        r.packing_region === input.packingRegionEu &&
        r.sales_region === input.salesRegionEu,
    );
    const fmc2rate = packagingRates.find(
      (r) =>
        r.source === input.sourceUs &&
        r.packing_key === key &&
        r.packing_region === input.packingRegionUs &&
        r.sales_region === input.salesRegionUs,
    );

    return {
      key,
      fmc1quantity: fmc1rate?.fmc_quantity || 0,
      fmc2quantity: fmc2rate?.fmc_quantity || 0,
    };
  });

  return inputs;
}
