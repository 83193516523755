import { useEffect, useMemo, useState } from 'react';
import { PackingNonstandardCostsNumberKey, PackingNonstandardCostsRow } from './packaging.types';
import { Calculator } from '@core/calculator/calculator';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { Message } from '@frontend/common/lib/models';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { getErrorStatement, showInfoToast } from '@frontend/common/lib/functions';
import { DBPackingNonstandardCost } from '@core/schemas/db/schema.db.packaging';
import { PasteChanges } from '@frontend/table/lib';
import {
  getPackingNonstandardKeyDescription,
  getPackingNonstandardKeyIsFinalPack,
} from '@core/util/util.getPackingNonstandardKeyInfo';
import { PackSource, PackingNonstandardKey } from '@core/types/types.packaging';
import { getFreshId } from '@core/util/util.geFreshId';
import { Unpacked } from '@core/util/util.typing';
import { RegionFMC } from '@core/schemas/schema.common';

type FMCNumbersNullable = {
  fmc1: number;
  fmc2: number;
};

export type UsePackingNonstandardCostsRowsReturnValue = {
  rows: PackingNonstandardCostsRow[];
  totals: {
    cost: FMCNumbersNullable;
    quantity: FMCNumbersNullable;
    machineTime: FMCNumbersNullable;
    operations: FMCNumbersNullable;
  };
  handlers: {
    addRow: () => void;
    removeRow: (rowId: string) => void;
    copyRow: (rowId: string) => void;
    changeDescription: (rowId: string, newDescription: string) => void;
    changePackingType: (rowId: string, newPackingType: PackingNonstandardKey) => void;
    changeNumber: (rowId: string, key: PackingNonstandardCostsNumberKey, newNumber: number) => void;
    onPaste: (updates: PasteChanges<PackingNonstandardCostsRow>) => void;
    importRows: (rows: DBPackingNonstandardCost[]) => void;
    clearPackingNonstandardFinalCosts: (fmcRegion: RegionFMC) => void;
  };
  messages: Message[];
};

export function usePackingNonstandardCostsRows(props: {
  payloadPackingNonstandardCosts:
    | PackagingGetEndpointResponse['packingNonstandardCosts']
    | undefined;
  packingNonstandardRates: PackagingGetEndpointResponse['packingNonstandardRates'] | undefined;
  selectedSourceEU: PackSource | undefined;
  selectedSourceUS: PackSource | undefined;
  fmcYear: number | undefined;
  readOnly?: boolean;
}): UsePackingNonstandardCostsRowsReturnValue {
  const {
    fmcYear,
    payloadPackingNonstandardCosts,
    packingNonstandardRates,
    selectedSourceEU,
    selectedSourceUS,
    readOnly,
  } = props;

  const [prePackingNonstandardCostsRows, setPrePackingNonstandardCostsRows] = useState<
    Omit<PackingNonstandardCostsRow, 'fmc1cost' | 'fmc2cost' | 'report' | 'description'>[]
  >([]);
  const [
    packingNonstandardCostsRowsWithoutDescription,
    setPackingNonstandardCostsRowsWithoutDescription,
  ] = useState<Omit<PackingNonstandardCostsRow, 'description'>[]>([]);
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});

  const {
    addMessage: addLocalMessage,
    clearMessages: clearLocalMessages,
    messages: localMessages,
  } = useMessages();

  useEffect(() => {
    if (!payloadPackingNonstandardCosts) {
      setPrePackingNonstandardCostsRows([]);
      return;
    }

    const newDescriptions: typeof descriptions = {};

    payloadPackingNonstandardCosts.forEach(
      (prr) => (newDescriptions[prr.row_id] = prr.description),
    );

    setDescriptions(newDescriptions);

    setPrePackingNonstandardCostsRows(
      payloadPackingNonstandardCosts.map((prr) => ({
        rowId: prr.row_id,
        packingType: prr.packing_type,
        fmc1operators: prr.fmc1_number_of_operators,
        fmc2operators: prr.fmc2_number_of_operators,
        fmc1machineTime: prr.fmc1_machine_time,
        fmc2machineTime: prr.fmc2_machine_time,
        fmc1quantity: prr.fmc1_quantity,
        fmc2quantity: prr.fmc2_quantity,
        bun: prr.bun,
        currency: prr.currency,
        report: [],
      })),
    );
  }, [payloadPackingNonstandardCosts]);

  function addRow() {
    const rowId = getFreshId();

    setPrePackingNonstandardCostsRows((curr) =>
      curr.concat({
        rowId,
        packingType: '',
        fmc1operators: 0,
        fmc2operators: 0,
        fmc1machineTime: 0,
        fmc2machineTime: 0,
        fmc1quantity: 0,
        fmc2quantity: 0,
        bun: 'PC',
        currency: 'DKK',
      }),
    );

    setDescriptions((curr) => ({ ...curr, [rowId]: '' }));
  }

  function removeRow(rowId: string) {
    setPrePackingNonstandardCostsRows((curr) => curr.filter((r) => r.rowId !== rowId));
    setDescriptions((curr) => {
      const newDescription = { ...curr };
      delete newDescription[rowId];
      return newDescription;
    });
  }

  function copyRow(rowId: string) {
    const newRowId = getFreshId();

    setPrePackingNonstandardCostsRows((curr) => {
      const r = curr.find((r) => r.rowId === rowId);

      if (!r) {
        return curr;
      }

      return curr.concat({ ...r, rowId: newRowId });
    });
    setDescriptions((curr) => ({ ...curr, [newRowId]: curr[rowId] || '' }));
  }

  function changeDescription(rowId: string, newDescription: string) {
    setDescriptions((curr) => ({ ...curr, [rowId]: newDescription }));
  }

  function changePackingType(rowId: string, newPackingType: PackingNonstandardKey) {
    setPrePackingNonstandardCostsRows((curr) =>
      curr.map((r) => (r.rowId === rowId ? { ...r, packingType: newPackingType } : r)),
    );
  }

  function changeNumber(rowId: string, key: PackingNonstandardCostsNumberKey, newNumber: number) {
    setPrePackingNonstandardCostsRows((curr) =>
      curr.map((r) => (r.rowId === rowId ? { ...r, [key]: newNumber } : r)),
    );
  }

  function clearPackingNonstandardFinalCosts(fmcRegion: RegionFMC) {
    setPrePackingNonstandardCostsRows((curr) => {
      let newPrePackingNonstandardCostsRows = curr.map((pnsc) => {
        if (!getPackingNonstandardKeyIsFinalPack(pnsc.packingType)) {
          return pnsc;
        }

        const newPnsc = structuredClone(pnsc);

        if (fmcRegion === 'FMC1') {
          newPnsc.fmc1machineTime = 0;
          newPnsc.fmc1operators = 0;
          newPnsc.fmc1quantity = 0;
        } else if (fmcRegion === 'FMC2') {
          newPnsc.fmc2machineTime = 0;
          newPnsc.fmc2operators = 0;
          newPnsc.fmc2quantity = 0;
        }

        return newPnsc;
      });

      // do a second pass to check if some final pack rows is now completely empty
      // if that is the case we remove them entirely
      newPrePackingNonstandardCostsRows = newPrePackingNonstandardCostsRows.filter(
        (pnsc) =>
          pnsc.fmc1machineTime ||
          pnsc.fmc1operators ||
          pnsc.fmc1quantity ||
          pnsc.fmc2machineTime ||
          pnsc.fmc2operators ||
          pnsc.fmc2quantity,
      );

      return newPrePackingNonstandardCostsRows;
    });
  }

  useEffect(() => {
    clearLocalMessages(/packing-nonstandard-cost.*/);

    packingNonstandardCostsRowsWithoutDescription.forEach((pnsc) => {
      pnsc.report
        .filter((report) => report.isError)
        .forEach((report) => {
          addLocalMessage({
            id: `packing-nonstandard-cost_error_${pnsc.rowId}`,
            message: `${getPackingNonstandardKeyDescription(pnsc.packingType)}: ${
              report.shortDescription
            }`,
          });
        });
    });
  }, [packingNonstandardCostsRowsWithoutDescription, clearLocalMessages, addLocalMessage]);

  useEffect(() => {
    if (!fmcYear || !selectedSourceEU || !selectedSourceUS) {
      setPackingNonstandardCostsRowsWithoutDescription([]);
      return;
    }

    (async () => {
      const preRows: Unpacked<typeof packingNonstandardCostsRowsWithoutDescription>[] =
        await Promise.all(
          prePackingNonstandardCostsRows.map(async (r) => {
            if (r.packingType === '') {
              return {
                ...r,
                fmc1cost: 0,
                fmc2cost: 0,
                report: [],
              };
            }

            try {
              const { fmc1_cost, fmc2_cost, report } =
                await Calculator.Pack.Packing.NonstandardCost.Calculate({
                  input: {
                    packingNonstandardCost: r,
                    sourceEu: selectedSourceEU,
                    sourceUs: selectedSourceUS,
                    fmcYear,
                  },
                  cache: {
                    packingNonstandardRates: packingNonstandardRates,
                    cost: readOnly
                      ? {
                          fmc1_cost: payloadPackingNonstandardCosts?.find(
                            (prr) => prr.row_id === r.rowId,
                          )?.fmc1_cost,
                          fmc2_cost: payloadPackingNonstandardCosts?.find(
                            (prr) => prr.row_id === r.rowId,
                          )?.fmc2_cost,
                        }
                      : undefined,
                  },
                });

              return { ...r, fmc1cost: fmc1_cost, fmc2cost: fmc2_cost, report };
            } catch (error) {
              const statement = getErrorStatement(error);
              return { ...r, fmc1cost: 0, fmc2cost: 0, report: statement ? [statement] : [] };
            }
          }),
        );

      setPackingNonstandardCostsRowsWithoutDescription(preRows);
    })();
  }, [
    readOnly,
    prePackingNonstandardCostsRows,
    payloadPackingNonstandardCosts,
    fmcYear,
    packingNonstandardRates,
    selectedSourceEU,
    selectedSourceUS,
    addLocalMessage,
    clearLocalMessages,
  ]);

  const packingNonstandardCostsRows = useMemo(
    () =>
      packingNonstandardCostsRowsWithoutDescription.map((r) => ({
        ...r,
        description: descriptions[r.rowId] || '',
      })),
    [packingNonstandardCostsRowsWithoutDescription, descriptions],
  );

  const totalCost = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc1cost',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc2cost',
      ),
    }),
    [packingNonstandardCostsRowsWithoutDescription],
  );

  const totalQuantity = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc1quantity',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc2quantity',
      ),
    }),
    [packingNonstandardCostsRowsWithoutDescription],
  );

  const totalMachineTime = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc1machineTime',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc2machineTime',
      ),
    }),
    [packingNonstandardCostsRowsWithoutDescription],
  );

  const totalOperations = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc1operators',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packingNonstandardCostsRowsWithoutDescription,
        'fmc2operators',
      ),
    }),
    [packingNonstandardCostsRowsWithoutDescription],
  );

  function onPaste(updates: PasteChanges<PackingNonstandardCostsRow>) {
    updates.forEach(({ row, changes }) => {
      if (typeof changes.description === 'string')
        changeDescription(row.rowId, changes.description);
      if (typeof changes.fmc1operators === 'number')
        changeNumber(row.rowId, 'fmc1operators', changes.fmc1operators);
      if (typeof changes.fmc2operators === 'number')
        changeNumber(row.rowId, 'fmc2operators', changes.fmc2operators);
      if (typeof changes.fmc1machineTime === 'number')
        changeNumber(row.rowId, 'fmc1machineTime', changes.fmc1machineTime);
      if (typeof changes.fmc2machineTime === 'number')
        changeNumber(row.rowId, 'fmc2machineTime', changes.fmc2machineTime);
      if (typeof changes.fmc1quantity === 'number')
        changeNumber(row.rowId, 'fmc1quantity', changes.fmc1quantity);
      if (typeof changes.fmc2quantity === 'number')
        changeNumber(row.rowId, 'fmc2quantity', changes.fmc2quantity);
    });
  }

  function importRows(rows: DBPackingNonstandardCost[]) {
    if (rows.length === 0) {
      showInfoToast('No packing non-standard costs to import');
      return;
    }

    setPrePackingNonstandardCostsRows(
      rows.map((r) => ({
        rowId: r.row_id,
        packingType: r.packing_type,
        fmc1machineTime: r.fmc1_machine_time,
        fmc1operators: r.fmc1_number_of_operators,
        fmc1quantity: r.fmc1_quantity,
        fmc2machineTime: r.fmc2_machine_time,
        fmc2operators: r.fmc2_number_of_operators,
        fmc2quantity: r.fmc2_quantity,
        bun: r.bun,
        currency: r.currency,
      })),
    );

    const newDescriptions: typeof descriptions = {};
    rows.forEach((r) => (newDescriptions[r.row_id] = r.description));
    setDescriptions(newDescriptions);
  }

  return {
    rows: packingNonstandardCostsRows,
    totals: {
      cost: totalCost,
      quantity: totalQuantity,
      machineTime: totalMachineTime,
      operations: totalOperations,
    },
    handlers: {
      addRow,
      removeRow,
      copyRow,
      changeDescription,
      changePackingType,
      changeNumber,
      onPaste,
      importRows,
      clearPackingNonstandardFinalCosts,
    },
    messages: localMessages,
  };
}
