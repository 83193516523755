import { PACKING_KEY_ID_TO_TYPE } from '@core/const/const.PACKING_KEYS';
import {
  DBPackingNonstandardCost,
  DBPackingStandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { getPackingNonstandardKeyIsFinalPack } from '@core/util/util.getPackingNonstandardKeyInfo';

export function calculate_packing_numberOfFinalPacks(
  packingStandardCosts:
    | Pick<DBPackingStandardCost, 'packing_key' | 'fmc1_quantity' | 'fmc2_quantity'>[]
    | undefined,
  packingNonstandardCosts:
    | Pick<DBPackingNonstandardCost, 'packing_type' | 'fmc1_quantity' | 'fmc2_quantity'>[]
    | undefined,
): {
  fmc1: number;
  fmc2: number;
  fmc1finalPacksStandard: number;
  fmc2finalPacksStandard: number;
  fmc1finalPacksNonstandard: number;
  fmc2finalPacksNonstandard: number;
} {
  const fmc1finalPacksStandard =
    packingStandardCosts?.reduce(
      (sum, psc) =>
        sum + (PACKING_KEY_ID_TO_TYPE[psc.packing_key] === 'FINAL_PACK' ? psc.fmc1_quantity : 0),
      0,
    ) || 0;

  const fmc1finalPacksNonstandard =
    packingNonstandardCosts?.reduce(
      (sum, pnsc) =>
        pnsc.packing_type === ''
          ? sum
          : sum + (getPackingNonstandardKeyIsFinalPack(pnsc.packing_type) ? pnsc.fmc1_quantity : 0),
      0,
    ) || 0;

  const fmc2finalPacksStandard =
    packingStandardCosts?.reduce(
      (sum, psc) =>
        sum + (PACKING_KEY_ID_TO_TYPE[psc.packing_key] === 'FINAL_PACK' ? psc.fmc2_quantity : 0),
      0,
    ) || 0;

  const fmc2finalPacksNonstandard =
    packingNonstandardCosts?.reduce(
      (sum, pnsc) =>
        pnsc.packing_type === ''
          ? sum
          : sum + (getPackingNonstandardKeyIsFinalPack(pnsc.packing_type) ? pnsc.fmc2_quantity : 0),
      0,
    ) || 0;

  const fmc1 = fmc1finalPacksStandard + fmc1finalPacksNonstandard;
  const fmc2 = fmc2finalPacksStandard + fmc2finalPacksNonstandard;

  return {
    fmc1,
    fmc2,
    fmc1finalPacksNonstandard,
    fmc1finalPacksStandard,
    fmc2finalPacksNonstandard,
    fmc2finalPacksStandard,
  };
}
