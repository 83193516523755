import { useContext, useMemo } from 'react';
import { getProductCostsOverviewColumns } from '@frontend/common/lib/common.columns';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { CostOverviewRow } from '@frontend/common/lib/models';
import { Table } from '@frontend/table/table';
import { useElementsCostsOverviewRows } from '../logic/useElementsCostsOverviewRows';
import { ExportButton } from '@frontend/common/components/ExportButton';
import { ElementsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.elements';

interface ElementsCostOverviewProps {
  productCosts: ElementsGetEndpointResponse['productCosts'];
  elementCosts: ElementsGetEndpointResponse['elementCosts'];
}

export function ElementsCostOverview(props: ElementsCostOverviewProps) {
  const { userData } = useContext(UserDataContext);

  const { cc_LEGOElements, cc_PurchasedElements, cc_OverdoseElements, total } =
    useElementsCostsOverviewRows({
      elementCosts: props.elementCosts,
      productCosts: props.productCosts,
      scrapRatePercentEU: undefined,
      scrapRatePercentUS: undefined,
    });

  const shownRows = useMemo<CostOverviewRow[]>(
    () => [cc_LEGOElements, cc_PurchasedElements, cc_OverdoseElements, total],
    [cc_LEGOElements, cc_PurchasedElements, cc_OverdoseElements, total],
  );

  const columns = useMemo(
    () => getProductCostsOverviewColumns(!!userData?.comma_as_decimal_seperator),
    [userData?.comma_as_decimal_seperator],
  );

  return (
    <Table
      id="elements_product-costs"
      rows={shownRows}
      removeInfoText
      headerContent={<ExportButton rows={shownRows} columns={columns} />}
      removeSearch
      removeLastRowBottomBorder
      stackHeaderTitles
      rowKey="costComp"
      columns={columns}
    />
  );
}
