import { Table } from '@frontend/table/table';
import { getAMLogsForGroupColumns, getGroupMemberColumns } from '../administration.columns.am';
import { Button } from '@lego/klik-ui-button';
import { useCallback, useContext, useMemo, useState } from 'react';
import { FInput } from '@frontend/form/components/FInput';
import { NCConfirmModal } from '@frontend/common/components/NCConfirmModal';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showSuccessToast } from '@frontend/common/lib/functions';
import { REGEX_EMAIL } from '@core/types/types.regex';
import { ADGroupWithMembers } from '@core/schemas/schema.common';
import {
  AMAddMembersToGroupsEndpoint,
  AMGetLogsForGroupEndpoint,
  AMRemoveMembersEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.am';

interface GroupModalContentProps {
  group: ADGroupWithMembers;
  emailLov: string[];
  isRefreshing: boolean;
  refresh: () => void;
}

export function GroupModalContent(props: GroupModalContentProps) {
  const {
    data: logs,
    loading: logsLoading,
    queryFunction: fetchLogs,
  } = useEndpoint({
    endpoint: AMGetLogsForGroupEndpoint,
    input: { groupId: props.group.id },
    errorHandling: { header: 'Fetching logs' },
  });

  const [email, setEmail] = useState('');
  const [isAddingEmail, setIsAddingEmail] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState('');
  const [isWorking, setIsWorking] = useState(false);

  const { getName } = useContext(UserDataContext);

  const { group, refresh } = props;

  const addMember = useCallback(
    async (email: string) => {
      setIsAddingEmail(true);
      const [err] = await callEndpoint({
        endpoint: AMAddMembersToGroupsEndpoint,
        input: { emails: [email], groupIds: [group.id] },
        errorHandling: { header: 'Adding user to group' },
      });
      setIsAddingEmail(false);

      if (err) {
        return;
      }

      setEmail('');
      showSuccessToast(
        'Added user to group',
        `Successfully added ${getName(email)} to ${group.name}`,
      );
      fetchLogs();
      refresh();
    },
    [getName, group, refresh, fetchLogs],
  );

  const removeMember = useCallback(
    async (email: string) => {
      setIsWorking(true);
      const [err] = await callEndpoint({
        endpoint: AMRemoveMembersEndpoint,
        input: { emails: [email], groupId: group.id },
        errorHandling: { header: 'Removing member from group' },
      });
      setIsWorking(false);

      if (err) {
        return;
      }

      showSuccessToast(
        'Removed member from group',
        `Successfully removed ${getName(email)} from ${group.name}`,
      );
      setEmailToDelete('');
      refresh();
      fetchLogs();
    },

    [getName, group, refresh, fetchLogs],
  );

  const columns = useMemo(
    () =>
      getGroupMemberColumns({
        onRemoveMember: setEmailToDelete,
        isWorking,
      }),
    [isWorking],
  );

  const logsColumns = useMemo(() => getAMLogsForGroupColumns(getName), [getName]);

  return (
    <div>
      {emailToDelete && (
        <NCConfirmModal
          isOpen={!!emailToDelete}
          description={`Are you sure you want to remove ${getName(emailToDelete)} from ${
            props.group.name
          }?`}
          header="Remove member"
          onCancel={() => setEmailToDelete('')}
          onConfirm={async () => await removeMember(emailToDelete)}
          variant="warning"
        />
      )}
      <div style={{ marginBottom: 8, fontSize: 18 }}>Members</div>
      <Table
        id="administration_group-members"
        rows={group.members || []}
        rowKey="email"
        columns={columns}
        isRefreshing={props.isRefreshing || isWorking}
        itemType="member"
        itemsPerPage={10}
        fixedHeight={297.5}
      />
      <div style={{ marginTop: props.group.members.length > 0 ? 0 : 24, marginBottom: 8 }}>
        <div style={{ fontSize: 16 }}>Add member by email</div>
        <div style={{ display: 'flex', columnGap: 8, alignItems: 'end' }}>
          <FInput
            value={email}
            placeholder="Email"
            onChange={setEmail}
            editable
            suggestions={props.emailLov.slice(20)}
            align="start"
            isInvalid={!!email && !REGEX_EMAIL.test(email)}
            inputStyle={{ width: 340 }}
            onEnter={() => {
              if (!email) {
                return;
              }
              addMember(email);
            }}
          />
          <Button
            size="sm"
            disabled={!email || !REGEX_EMAIL.test(email)}
            onClick={() => addMember(email)}
            isLoading={isAddingEmail}
            className="button-green"
          >
            Add
          </Button>
        </div>
      </div>
      <div style={{ marginBottom: 8, marginTop: 48 }}>Logs</div>
      <Table
        id="administration_your-ad-groups-logs"
        rowKey="SK"
        rows={logs || []}
        columns={logsColumns}
        isRefreshing={logsLoading}
        itemsPerPage={10}
        fixedHeight={297.5}
        itemType="log"
      />
    </div>
  );
}
