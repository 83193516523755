import { DBPackingNonstandardKey } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { PackingNonstandardKey } from '@core/types/types.packaging';

export const PackingNonstandardKeyExample: OmitDBRecord<DBPackingNonstandardKey>[] = [
  {
    key: PackingNonstandardKey.BIPrepack,
    description: 'abc',
    is_final_pack: false,
  },
];
