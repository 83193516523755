import { Region, RegionFMC } from '@core/schemas/schema.common';

export type PageRoute =
  | '/'
  | '/elements'
  | '/packaging'
  | '/building-instructions'
  | '/final-costs'
  | '/reporting/elements-costs'
  | '/reporting/elements-errors'
  | '/reporting/packaging'
  | '/reporting/building-instructions'
  | '/reporting/bi-engineering'
  | '/reporting/report-bi-script'
  | '/reporting/costing-status'
  | '/reporting/final-costs'
  | '/administration'
  | '/settings';

export const pageRoutes = {
  WelcomePage: '/' as PageRoute,
  Elements: '/elements' as PageRoute,
  Packaging: '/packaging' as PageRoute,
  Instructions: '/building-instructions' as PageRoute,
  'Final costs': '/final-costs' as PageRoute,
  Reporting: {
    'Elements costs': '/reporting/elements-costs' as PageRoute,
    'Elements errors': '/reporting/elements-errors' as PageRoute,
    Packaging: '/reporting/packaging' as PageRoute,
    'Building instructions': '/reporting/building-instructions' as PageRoute,
    'BI report for Engineering': '/reporting/bi-engineering' as PageRoute,
    'Report to BI script': '/reporting/report-bi-script' as PageRoute,
    'Costing status': '/reporting/costing-status' as PageRoute,
    'Final costs': '/reporting/final-costs' as PageRoute,
  },
};

const otherRoutes = {
  Settings: '/settings' as PageRoute,
  Administration: '/administration' as PageRoute,
};

export const allRoutes = { ...pageRoutes, ...otherRoutes };

export const BI_STATUS_TO_PRETTY: { [status in string]: string } = {
  INI: 'Initial',
  APP: 'Approved',
};

export const FMCREGION_TO_PRETTY: { [fmc in RegionFMC]: string } = {
  FMC1: 'EU',
  FMC2: 'US',
};

export const REGION_TO_PRETTY: { [fmc in Region]: string } = {
  EU: 'EU',
  US: 'US',
};

export const LF_LAST_VISITED = 'nc_lf_last-visited';
export const LF_SUGGESTIONS = 'nc_lf_suggestions';

export const COLOR_SELECTED_BLUE = '#247bbf';
