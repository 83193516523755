import { z } from 'zod';
import { DBMaraSchema } from '@core/schemas/db/schema.db.mara';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import {
  DBPackagingNonstandardCostSchema,
  DBPackagingRateSchema,
  DBPackagingScrapRateSchema,
  DBPackagingStandardCostSchema,
  DBPackingNonstandardKeySchema,
} from '@core/schemas/db/schema.db.packaging';
import {
  RegionEnum,
  RegionFMCRecordSchema,
  RevisionErrorSchema,
} from '@core/schemas/schema.common';
import { DBProdHeadPackSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackSourceSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackingStandardFinalPackRateSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackingStandardPrepackRateSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackingStandardCostSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackingNonstandardCostSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackingNonstandardRateSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackagingAddonRateSchema } from '@core/schemas/db/schema.db.packaging';
import { DBPackagingFoilRateSchema } from '@core/schemas/db/schema.db.packaging';
import { OMIT_DB_RECORD_EXCEPT_PK } from '../const/schema.const.OMIT_DB_RECORD';
import { CostComponent } from '@core/types/types.costComponent';
import { PackSource, PackingKeyId, PackingNonstandardKey } from '@core/types/types.packaging';
import { PackagingSizeSchema } from '../interface/schema.interface';
import { PK } from '@core/types/types.pk';

// PackagingGetEndpoint
const PackagingGetResponseSchema = z.object({
  prodHead: DBProdHeadPackSchema.omit(OMIT_DB_RECORD_EXCEPT_PK),
  maxRevision: z.number().int(),
  description: z.string(),
  erectCode: z.string().optional(),
  productCosts: z.object({
    ppo: z.object({
      [CostComponent.Packing]: RegionFMCRecordSchema.partial(),
      [CostComponent.Packaging]: RegionFMCRecordSchema.partial(),
    }),
  }),
  packagingSourceOptions: z.array(DBPackSourceSchema),
  packingStandardFinalPackRates: z.array(DBPackingStandardFinalPackRateSchema),
  packingStandardPrepackRates: z.array(DBPackingStandardPrepackRateSchema),
  packingStandardCosts: z.array(DBPackingStandardCostSchema),
  packingNonstandardCosts: z.array(DBPackingNonstandardCostSchema),
  packingNonstandardKeys: z.array(DBPackingNonstandardKeySchema),
  packingNonstandardRates: z.array(DBPackingNonstandardRateSchema),
  packagingAddOnRates: z.array(DBPackagingAddonRateSchema),
  packagingStandardCosts: z.array(DBPackagingStandardCostSchema),
  packagingNonstandardCosts: z.array(DBPackagingNonstandardCostSchema),
  packagingScrapRates: z.array(DBPackagingScrapRateSchema),
  packagingFoilRates: z.array(DBPackagingFoilRateSchema),
  packagingRates: z.array(DBPackagingRateSchema),
  ppoPackagingSize: PackagingSizeSchema.optional(),
});

export const PackagingGetEndpoint = defineEndpoint({
  path: '/packaging/get',
  input: z.object({
    productId: z.number().int(),
    rev: z.number().int().optional(),
  }),
  response: PackagingGetResponseSchema,
});
export type PackagingGetEndpointInput = z.infer<typeof PackagingGetEndpoint.input>;
export type PackagingGetEndpointResponse = z.infer<typeof PackagingGetEndpoint.response>;

// PackagingNewGetEndpoint
export const PackagingNewGetEndpoint = defineEndpoint({
  path: '/packaging/get/new',
  input: z.object({
    productId: z.number().int(),
  }),
  response: PackagingGetResponseSchema.extend({
    ...PackagingGetResponseSchema.shape,
    prodHead: z.object({
      ...PackagingGetResponseSchema.shape.prodHead.shape,
      source_eu: z.undefined(),
      source_us: z.undefined(),
      packing_region_eu: z.undefined(),
      packing_region_us: z.undefined(),
    }),
    maxRevision: z.literal(1),
    ppoPackagingSize: PackagingSizeSchema.optional(),
  }),
});
export type PackagingNewGetEndpointInput = z.infer<typeof PackagingNewGetEndpoint.input>;
export type PackagingNewGetEndpointResponse = z.infer<typeof PackagingNewGetEndpoint.response>;

// PackagingSaveEndpoint
export type PackagingSaveEndpointInput = z.infer<typeof PackagingSaveEndpoint.input>;
export type PackagingSaveEndpointResponse = z.infer<typeof PackagingSaveEndpoint.response>;
export const PackagingSaveEndpoint = defineEndpoint({
  path: '/packaging/save',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    revision: z.union([z.number().int(), z.literal('NEW')]),
    note: z.string(),
    estimationCode: z.string().optional(),
    alternativeCode: z.string().optional(),
    boxFillingDegree: z.number(),
    packagingSize: z.number().int().optional(),
    modelBag: z.boolean(),
    packSourceEU: z.nativeEnum(PackSource).optional(),
    packSourceUS: z.nativeEnum(PackSource).optional(),
    packingRegionEU: RegionEnum.optional(),
    packingRegionUS: RegionEnum.optional(),
    salesRegionEU: RegionEnum,
    salesRegionUS: RegionEnum,
    packingStandardCostsInput: z.array(
      z.object({
        packingKey: z.nativeEnum(PackingKeyId),
        fmc1quantity: z.number().int(),
        fmc2quantity: z.number().int(),
      }),
    ),
    packingNonstandardCostsInput: z.array(
      z.object({
        rowId: z.string(),
        packingType: z.nativeEnum(PackingNonstandardKey).or(z.literal('')),
        description: z.string(),
        fmc1operators: z.number(),
        fmc2operators: z.number(),
        fmc1machineTime: z.number(),
        fmc2machineTime: z.number(),
        fmc1quantity: z.number(),
        fmc2quantity: z.number(),
      }),
    ),
    packagingAddOns: z.object({
      embossing: z.boolean(),
      hotfoil: z.boolean(),
      uvLacquer: z.boolean(),
      partialUvLacquer: z.boolean(),
      windowCutout: z.boolean(),
      windowFoil: z.boolean(),
    }),
    packagingStandardCostsInput: z.array(
      z.object({
        key: z.string(),
        description: z.string(),
        fmc1quantity: z.number(),
        fmc2quantity: z.number(),
        fmc1manualCost: z.number().optional(),
        fmc2manualCost: z.number().optional(),
        custom: z.boolean().optional(),
      }),
    ),
    packagingNonstandardCostsInput: z.array(
      z.object({
        rowId: z.string(),
        description: z.string(),
        fmc1quantity: z.number(),
        fmc2quantity: z.number(),
        fmc1manualCost: z.number().optional(),
        fmc2manualCost: z.number().optional(),
        fmc1material: z.number().int().optional(),
        fmc2material: z.number().int().optional(),
      }),
    ),
    domainUsername: z.string(),
    _createdItemsReport: z.boolean().optional(),
    _fromPacmac: z.boolean().optional(),
  }),
  response: z.object({
    savedRevision: z.number().int(),
    revisionErrors: z.array(RevisionErrorSchema).optional(),
    createdItems: z.array(z.object({ PK: z.nativeEnum(PK), SK: z.string() })).optional(),
  }),
});

// PackagingSizesListEndpoint
export const PackagingSizesListEndpoint = defineEndpoint({
  path: '/packaging/sizes-list',
  input: z.object({}),
  response: z.array(PackagingSizeSchema),
});
export type PackagingSizesListEndpointInput = z.infer<typeof PackagingSizesListEndpoint.input>;
export type PackagingSizesListEndpointResponse = z.infer<
  typeof PackagingSizesListEndpoint.response
>;

// PackagingRatesListEndpoint
export const PackagingRatesListEndpoint = defineEndpoint({
  path: '/packaging/packaging-rates-list',
  input: z.object({
    fmcYear: z.number().int(),
    packingSize: z.number().int(),
    fmc1source: z.nativeEnum(PackSource),
    fmc2source: z.nativeEnum(PackSource),
  }),
  response: z.object({
    fmc1rates: z.array(DBPackagingRateSchema),
    fmc2rates: z.array(DBPackagingRateSchema),
  }),
});
export type PackagingRatesListEndpointInput = z.infer<typeof PackagingRatesListEndpoint.input>;
export type PackagingRatesListEndpointResponse = z.infer<
  typeof PackagingRatesListEndpoint.response
>;

// MaterialGet
export const MaterialGetEndpoint = defineEndpoint({
  path: '/material/get',
  input: z.object({
    materialId: z.number().int(),
  }),
  response: DBMaraSchema.merge(z.object({ cost: z.number().optional() })),
});
export type MaterialGetEndpointInput = z.infer<typeof MaterialGetEndpoint.input>;
export type MaterialGetEndpointResponse = z.infer<typeof MaterialGetEndpoint.response>;

// PackingStandardCostsEndpoint
export const PackingStandardCostsEndpoint = defineEndpoint({
  path: '/packaging/packing-standard-costs',
  input: z.object({
    productId: z.number(),
    revision: z.number(),
  }),
  response: z.array(DBPackingStandardCostSchema),
});

// PackingNonstandardCostsEndpoint
export const PackingNonstandardCostsEndpoint = defineEndpoint({
  path: '/packaging/packing-nonstandard-costs',
  input: z.object({
    productId: z.number(),
    revision: z.number(),
  }),
  response: z.array(DBPackingNonstandardCostSchema),
});

// PackagingStandardCostsEndpoint
export const PackagingStandardCostsEndpoint = defineEndpoint({
  path: '/packaging/packaging-standard-costs',
  input: z.object({
    productId: z.number(),
    revision: z.number(),
  }),
  response: z.array(DBPackagingStandardCostSchema),
});

// PackagingNonstandardCostsEndpoint
export const PackagingNonstandardCostsEndpoint = defineEndpoint({
  path: '/packaging/packaging-nonstandard-costs',
  input: z.object({
    productId: z.number(),
    revision: z.number(),
  }),
  response: z.array(DBPackagingNonstandardCostSchema),
});
