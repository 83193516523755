import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Region, RegionEnum, RegionFMC } from '@core/schemas/schema.common';
import { PackSource } from '@core/types/types.packaging';
import { sortPackSource } from '@core/util/util.sortPackSource';
import { showInternalApplicationError } from '@frontend/common/lib/functions';
import { FLabel } from '@frontend/form/components/FLabel';
import { FSelect } from '@frontend/form/components/FSelect';

interface PackagingSourceAndRegionsProps {
  readOnly: boolean;
  sourceOptions: PackagingGetEndpointResponse['packagingSourceOptions'];
  selectedSourceEU: PackSource | undefined;
  selectedSourceUS: PackSource | undefined;
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  salesRegionEU: Region;
  salesRegionUS: Region;
  setPackingRegionEU?(region: Region | ''): void;
  setPackingRegionUS?(region: Region | ''): void;
  onSelectedSourceChange?(fmcRegion: RegionFMC, newSource: PackSource | undefined): void;
}

const REGION_OPTIONS = [
  { value: RegionEnum.Enum.EU, text: 'EU' },
  { value: RegionEnum.Enum.US, text: 'US' },
];

export function PackagingSourceAndRegions(props: PackagingSourceAndRegionsProps) {
  return (
    <div style={{ display: 'flex', columnGap: 48 }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'min-content auto',
          columnGap: 8,
          rowGap: 4,
          alignItems: 'center',
        }}
      >
        <div style={{ gridColumn: 'span 2' }}>EU</div>
        <FLabel label="Source" />
        <FSelect
          value={props.selectedSourceEU || ''}
          placeholder="Select source"
          onChange={(opt) => {
            if (!props.onSelectedSourceChange) {
              showInternalApplicationError();
              return;
            }
            props.onSelectedSourceChange('FMC1', opt as PackSource);
          }}
          options={props.sourceOptions
            .map((opt) => ({
              value: opt.source,
              text: opt.description,
            }))
            .sort((a, b) => sortPackSource(a.value, b.value))}
          align="start"
          editable={!props.readOnly}
          autoFit
        />
        <FLabel label="Packing" />
        <FSelect
          options={REGION_OPTIONS}
          value={props.packingRegionEU || ''}
          placeholder="Select region"
          editable={!props.readOnly}
          align="start"
          onChange={(v) => {
            if (!props.setPackingRegionEU) {
              showInternalApplicationError();
              return;
            }
            props.setPackingRegionEU(v as Region | '');
          }}
        />
        <FLabel label="Sales" />
        <FSelect options={REGION_OPTIONS} value={props.salesRegionEU} align="start" />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'min-content auto',
          columnGap: 8,
          rowGap: 4,
          alignItems: 'center',
        }}
      >
        <div style={{ gridColumn: 'span 2' }}>US</div>
        <FLabel label="Source" />
        <FSelect
          value={props.selectedSourceUS || ''}
          placeholder="Select source"
          onChange={(opt) => {
            if (!props.onSelectedSourceChange) {
              showInternalApplicationError();
              return;
            }
            props.onSelectedSourceChange('FMC2', opt as PackSource);
          }}
          options={props.sourceOptions
            .map((opt) => ({
              value: opt.source,
              text: opt.description,
            }))
            .sort((a, b) => sortPackSource(a.value, b.value))}
          align="start"
          editable={!props.readOnly}
          autoFit
        />
        <FLabel label="Packing" />
        <FSelect
          options={REGION_OPTIONS}
          value={props.packingRegionUS || ''}
          placeholder="Select region"
          editable={!props.readOnly}
          align="start"
          onChange={(v) => {
            if (!props.setPackingRegionUS) {
              showInternalApplicationError();
              return;
            }
            props.setPackingRegionUS(v as Region | '');
          }}
        />
        <FLabel label="Sales" />
        <FSelect options={REGION_OPTIONS} value={props.salesRegionUS} align="start" />
      </div>
    </div>
  );
}
