import { Calculator } from '@core/calculator/calculator';
import { TOTAL_KEY } from '@core/const/const.TOTAL_KEY';
import { DBProdCost } from '@core/schemas/db/schema.db.prod';
import { ElementsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.elements';
import { FinalCostsGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { CostComponent } from '@core/types/types.costComponent';

import { CostOverviewRow } from '@frontend/common/lib/models';
import { useMemo } from 'react';

export function useElementsCostsOverviewRows(props: {
  readOnly?: boolean;
  elementCosts: ElementsGetEndpointResponse['elementCosts'] | undefined;
  productCosts:
    | ElementsGetEndpointResponse['productCosts']
    | FinalCostsGetEndpointResponse['productCosts']['elements']
    | undefined;
  scrapRatePercentEU: number | undefined;
  scrapRatePercentUS: number | undefined;
  copyPpoToCost?: boolean;
}): {
  cc_LEGOElements: CostOverviewRow;
  cc_PurchasedElements: CostOverviewRow;
  cc_OverdoseElements: CostOverviewRow;
  cc_ElementsScrap: CostOverviewRow;
  cc_ElementsCosts: CostOverviewRow;
  total: CostOverviewRow;
} {
  const {
    readOnly,
    elementCosts,
    productCosts,
    scrapRatePercentEU,
    scrapRatePercentUS,
    copyPpoToCost,
  } = props;

  const cc_LEGOElements = useMemo(() => {
    const costPpoVariance = Calculator.Elements.CC_Elements.Full({
      elements: elementCosts || [],
      ppos: productCosts?.ppo[CostComponent.LEGOElements],
      copyPpoToCost,
      costComponent: CostComponent.LEGOElements,
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.LEGOElements,
      currency: 'DKK',
      description: 'LEGO elements',
      ...costPpoVariance,
    };
    return r;
  }, [productCosts, elementCosts, copyPpoToCost]);

  const cc_PurchasedElements = useMemo(() => {
    const costPpoVariance = Calculator.Elements.CC_Elements.Full({
      elements: elementCosts || [],
      ppos: productCosts?.ppo[CostComponent.PurchasedElements],
      copyPpoToCost: copyPpoToCost,
      costComponent: CostComponent.PurchasedElements,
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.PurchasedElements,
      currency: 'DKK',
      description: 'Purchased elements',
      ...costPpoVariance,
    };

    return r;
  }, [productCosts, elementCosts, copyPpoToCost]);

  const cc_OverdoseElements = useMemo(() => {
    const costPpoVariance = Calculator.Elements.CC_Elements.Full({
      elements: elementCosts || [],
      ppos: productCosts?.ppo[CostComponent.OverdoseElements as keyof typeof productCosts.ppo],
      copyPpoToCost: copyPpoToCost,
      costComponent: CostComponent.OverdoseElements,
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.OverdoseElements,
      currency: 'DKK',
      description: 'Overdose elements',
      ...costPpoVariance,
    };

    return r;
  }, [productCosts, elementCosts, copyPpoToCost]);

  const cc_ElementsScrap = useMemo(() => {
    const scrapCost: DBProdCost | undefined = (
      productCosts as FinalCostsGetEndpointResponse['productCosts']['elements'] | undefined
    )?.cost?.ELEMENTS_SCRAP;

    const costPpoVariance = Calculator.Elements.CC_ElementsScrap.Full({
      elements: elementCosts || [],
      ppos: productCosts?.ppo[CostComponent.ElementsScrap as keyof typeof productCosts.ppo],
      scrapRatePercentEU,
      scrapRatePercentUS,
      copyPpoToCost: copyPpoToCost,
      cache: {
        use: readOnly,
        cost: { fmc1cost: scrapCost?.fmc_eu_cost, fmc2cost: scrapCost?.fmc_us_cost },
      },
    });

    const r: CostOverviewRow = {
      costComp: CostComponent.ElementsScrap,
      currency: 'DKK',
      description: 'Element scrap elements',
      ...costPpoVariance,
    };

    return r;
  }, [readOnly, productCosts, elementCosts, scrapRatePercentEU, scrapRatePercentUS, copyPpoToCost]);

  const cc_ElementsCosts = useMemo(() => {
    const costPpoVariance = Calculator.Elements.Total({
      elements: elementCosts || [],
      ppos: {
        LEGOElements:
          productCosts?.ppo[CostComponent.LEGOElements as keyof typeof productCosts.ppo],
        PurchasedElements:
          productCosts?.ppo[CostComponent.PurchasedElements as keyof typeof productCosts.ppo],
        OverdoseElements:
          productCosts?.ppo[CostComponent.OverdoseElements as keyof typeof productCosts.ppo],
        ElementsScrap:
          productCosts?.ppo[CostComponent.ElementsScrap as keyof typeof productCosts.ppo],
      },
      scrapRatePercentEU,
      scrapRatePercentUS,
      copyPpoToCost: copyPpoToCost,
    });

    const r: CostOverviewRow = {
      costComp: 'ELEMENTS_TOTAL',
      currency: 'DKK',
      description: 'Elements total',
      ...costPpoVariance,
    };

    return r;
  }, [productCosts, elementCosts, scrapRatePercentEU, scrapRatePercentUS, copyPpoToCost]);

  const total = useMemo(() => {
    const rows = [cc_LEGOElements, cc_PurchasedElements, cc_OverdoseElements];

    const fmc1cost = Calculator.Common.SumList(rows, 'fmc1cost');
    const fmc2cost = Calculator.Common.SumList(rows, 'fmc2cost');

    const fmc1ppo = Calculator.Common.SumList(rows, 'fmc1ppo');
    const fmc2ppo = Calculator.Common.SumList(rows, 'fmc2ppo');

    const r: CostOverviewRow = {
      costComp: TOTAL_KEY,
      description: 'Total',
      fmc1cost,
      fmc1ppo: fmc1ppo,
      fmc1variance: Calculator.Common.Variance(fmc1ppo, fmc1cost),
      fmc2cost,
      fmc2ppo: fmc2ppo,
      fmc2variance: Calculator.Common.Variance(fmc2ppo, fmc2cost),
      currency: 'DKK',
    };

    return r;
  }, [cc_LEGOElements, cc_PurchasedElements, cc_OverdoseElements]);

  return {
    cc_LEGOElements: cc_LEGOElements,
    cc_PurchasedElements: cc_PurchasedElements,
    cc_OverdoseElements: cc_OverdoseElements,
    cc_ElementsScrap: cc_ElementsScrap,
    cc_ElementsCosts: cc_ElementsCosts,
    total,
  };
}
