import { DBElementsScrapRate, DBOHRate } from '@core/schemas/db/schema.db.common';
import {
  ElementsScrapRatesGetEndpoint,
  OverheadRatesGetEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { RegionFMC } from '@core/schemas/schema.common';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showErrorToast, showInternalApplicationError } from '@frontend/common/lib/functions';

import { FSelectOption } from '@frontend/form/lib/form.types';
import { useEffect, useMemo, useState } from 'react';

export type ScrapType = undefined | 1 | 2 | 3 | 4;
export type OverheadType = undefined | 1 | 2 | 3 | 4;

export function useScrapAndOH(input: {
  scrapTypeEU: number | undefined;
  scrapTypeUS: number | undefined;
  overheadTypeEU: number | undefined;
  overheadTypeUS: number | undefined;
  year: number | undefined;
}) {
  const { overheadTypeEU, overheadTypeUS, scrapTypeEU, scrapTypeUS, year } = input;

  const [selectedScrapRateEU, setSelectedScrapRateEU] = useState<DBElementsScrapRate>();
  const [selectedScrapRateUS, setSelectedScrapRateUS] = useState<DBElementsScrapRate>();
  const [selectedOverheadRateEU, setSelectedOverheadRateEU] = useState<DBOHRate>();
  const [selectedOverheadRateUS, setSelectedOverheadRateUS] = useState<DBOHRate>();
  const [scrapRates, setScrapRates] = useState<DBElementsScrapRate[]>();
  const [overheadRates, setOverheadRates] = useState<DBOHRate[]>();

  const invalidRates = useMemo(() => {
    if (selectedScrapRateEU && selectedScrapRateEU.year !== year) {
      return true;
    }
    if (selectedScrapRateUS && selectedScrapRateUS.year !== year) {
      return true;
    }

    if (selectedOverheadRateEU && selectedOverheadRateEU.year !== year) {
      return true;
    }
    if (selectedOverheadRateUS && selectedOverheadRateUS.year !== year) {
      return true;
    }

    return false;
  }, [
    year,
    selectedScrapRateEU,
    selectedScrapRateUS,
    selectedOverheadRateEU,
    selectedOverheadRateUS,
  ]);

  useEffect(() => {
    if (!year) {
      return;
    }

    (async () => {
      const [[, scrapRates], [, overheadRates]] = await Promise.all([
        callEndpoint({
          endpoint: ElementsScrapRatesGetEndpoint,
          input: { year },
          errorHandling: { header: 'Fetching scrap rates' },
        }),
        callEndpoint({
          endpoint: OverheadRatesGetEndpoint,
          input: { year },
          errorHandling: { header: 'Fetching overhead rates' },
        }),
      ]);

      if (scrapRates) {
        setScrapRates(scrapRates);
      }

      if (overheadRates) {
        setOverheadRates(overheadRates);
      }
    })();
  }, [year]);

  useEffect(() => {
    if (!scrapRates) {
      return;
    }

    if (!scrapTypeEU) {
      setSelectedScrapRateEU(undefined);
      return;
    }

    const scrapRate = scrapRates.find((sr) => sr.type === scrapTypeEU);

    if (!scrapRate) {
      showErrorToast('Unable to find selected scrap rate among options (EU)');
      return;
    }

    setSelectedScrapRateEU(scrapRate);
  }, [scrapTypeEU, scrapRates]);

  useEffect(() => {
    if (!scrapRates) {
      return;
    }

    if (!scrapTypeUS) {
      setSelectedScrapRateUS(undefined);
      return;
    }

    const scrapRate = scrapRates.find((sr) => sr.type === scrapTypeUS);

    if (!scrapRate) {
      showErrorToast('Unable to find selected scrap rate among options (US)');
      return;
    }

    setSelectedScrapRateUS(scrapRate);
  }, [scrapTypeUS, scrapRates]);

  useEffect(() => {
    if (!overheadRates) {
      return;
    }

    if (!overheadTypeEU) {
      setSelectedOverheadRateEU(undefined);
      return;
    }

    const overheadRate = overheadRates.find((ohr) => ohr.type === overheadTypeEU);

    if (!overheadRate) {
      showErrorToast('Unable to find selected overhead rate among options (EU)');
      return;
    }

    setSelectedOverheadRateEU(overheadRate);
  }, [overheadTypeEU, overheadRates]);

  useEffect(() => {
    if (!overheadRates) {
      return;
    }

    if (!overheadTypeUS) {
      setSelectedOverheadRateUS(undefined);
      return;
    }

    const overheadRate = overheadRates.find((ohr) => ohr.type === overheadTypeUS);

    if (!overheadRate) {
      showErrorToast('Unable to find selected overhead rate among options (US)');
      return;
    }

    setSelectedOverheadRateUS(overheadRate);
  }, [overheadTypeUS, overheadRates]);

  function updateScrapRate(type: ScrapType, region: RegionFMC) {
    if (!type) {
      if (region === 'FMC1') {
        setSelectedScrapRateEU(undefined);
      } else if (region === 'FMC2') {
        setSelectedScrapRateUS(undefined);
      }
      return;
    }

    const scrapRate = scrapRates?.find((sr) => sr.type === type);

    if (!scrapRate) {
      showInternalApplicationError();
      return;
    }

    if (region === 'FMC1') {
      setSelectedScrapRateEU(scrapRate);
    } else if (region === 'FMC2') {
      setSelectedScrapRateUS(scrapRate);
    }
  }

  function updateOverheadRate(type: OverheadType, region: RegionFMC) {
    if (!type) {
      if (region === 'FMC1') {
        setSelectedOverheadRateEU(undefined);
      } else if (region === 'FMC2') {
        setSelectedOverheadRateUS(undefined);
      }
      return;
    }

    const overheadRate = overheadRates?.find((ohr) => ohr.type === type);

    if (!overheadRate) {
      showInternalApplicationError();
      return;
    }

    if (region === 'FMC1') {
      setSelectedOverheadRateEU(overheadRate);
    } else if (region === 'FMC2') {
      setSelectedOverheadRateUS(overheadRate);
    }
  }

  const scrapOptions: FSelectOption[] = useMemo(
    () =>
      !scrapRates
        ? []
        : scrapRates.map<FSelectOption>((sr) => ({
            text: `${sr.type} - ${sr.description}`,
            value: `${sr.type}`,
            disabled: sr.disabled,
          })),
    [scrapRates],
  );
  const overheadOptions: FSelectOption[] = useMemo(
    () =>
      !overheadRates
        ? []
        : overheadRates.map<FSelectOption>((ohr) => ({
            text: `${ohr.type} - ${ohr.description}`,
            value: `${ohr.type}`,
            disabled: ohr.disabled,
          })),
    [overheadRates],
  );

  return {
    selectedOverheadRateEU,
    selectedOverheadRateUS,
    selectedScrapRateEU,
    selectedScrapRateUS,
    updateOverheadRate,
    updateScrapRate,
    overheadOptions,
    scrapOptions,
    invalidRates,
  };
}
