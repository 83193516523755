import { DBPackingStandardFinalPackRate } from '@core/schemas/db/schema.db.packaging';
import { Region } from '@core/schemas/schema.common';
import { ReportStatement, getRateReportStatement } from '../calculator.util.report';
import { PackagingSize } from '@core/schemas/interface/schema.interface';
import { PackSource } from '@core/types/types.packaging';

export function calculate_PACKINGCOST_findFinalPackRates(props: {
  packingKey: string;
  packaging: PackagingSize;
  standardRates: DBPackingStandardFinalPackRate[] | undefined;
  source: PackSource;
  year: number;
  packingRegion: Region;
  salesRegion: Region;
  region: Region;
}): { rate: DBPackingStandardFinalPackRate | undefined; report: ReportStatement[] } {
  const { packingKey, packaging, source, standardRates, year, packingRegion, salesRegion, region } =
    props;

  // first try to find rates with given packSize
  const coarseFilteredRates: DBPackingStandardFinalPackRate[] | undefined = standardRates?.filter(
    (rate) =>
      rate.year === year &&
      rate.packing_key === packingKey &&
      rate.packaging_facing === packaging.packaging_facing &&
      rate.source === source,
  );

  let rates = coarseFilteredRates?.filter(
    (rate) => rate.packaging_size === packaging.packaging_size,
  );

  // else look for rates with packSize blank (0)
  if (!rates || rates.length === 0) {
    rates = coarseFilteredRates?.filter((rate) => rate.packaging_size === 0);
  }

  const rate = rates?.find(
    (rate) => rate.packing_region === packingRegion && rate.sales_region === salesRegion,
  );

  return {
    rate,
    report: [
      getRateReportStatement({
        region,
        rateType: 'Packing standard final pack rate',
        rate: rate?.cost,
        rateIdentifier: [
          rate,
          ['year', 'packing_key', 'packaging_size', 'packing_region', 'sales_region', 'source'],
        ],
      }),
    ],
  };
}
