import { PK } from '@core/types/types.pk';

export const PK_TO_PRETTY_NAME: Record<PK, string> = {
  [PK.AMLog]: 'Access management logs',
  [PK.BICost]: 'Building instructions costs',
  [PK.BIRate]: 'Building instructions rates',
  [PK.BOM]: 'Bill of materials for a product',
  [PK.Config]: 'Application configuration',
  [PK.ElemCost]: 'Elements costs',
  [PK.ElemError]: 'Elements errors',
  [PK.ElementsScrapRate]: 'Element scrap rates',
  [PK.PackagingFoilRate]: 'Packaging foil rates',
  [PK.HiddenColumn]: 'Hidden table columns',
  [PK.MARA]: 'Materials',
  [PK.OHRate]: 'Overhead rates',
  [PK.PackSource]: 'Packing/packaging sources',
  [PK.PackingStandardCost]: 'Packing standard costs',
  [PK.PackingStandardFinalPackRate]: 'Packing standard final pack rates',
  [PK.PackingStandardPrepackRate]: 'Packing standard prepack rates',
  [PK.PackingNonstandardCost]: 'Packing nonstandard costs',
  [PK.PackingNonstandardKey]: 'Packing nonstandard key options',
  [PK.PackingNonstandardRate]: 'Packing nonstandard rates',
  [PK.PackagingAddOnRate]: 'Packaging add-on rates',
  [PK.PackagingStandardCost]: 'Packaging standard costs',
  [PK.PackagingNonstandardCost]: 'Packaging nonstandard costs',
  [PK.PackagingRate]: 'Packaging rates',
  [PK.PackagingScrapRate]: 'Packaging scrap rates',
  [PK.ProdCost]: 'Product costs',
  [PK.ProdExtra]: 'Extra product information',
  [PK.ProdHeadBI]: 'Product header for BI',
  [PK.ProdHeadElem]: 'Product header for elements',
  [PK.ProdHeadPack]: 'Product header for packaging',
  [PK.ProdHeadFC]: 'Product header for FC',
  [PK.ProdParams]: 'Product parameters',
  [PK.ProdPPO]: 'Product PPOs',
  [PK.UserSettings]: 'User settings',
  [PK.YearChangeRecord]: 'FMC year change overview record',
};
