import { C, Calculator, useCacheOrFetchQuery, useCacheOrFetchSingle } from '../calculator';
import { Calculate_FetchQuery, CalculatorCommonPPO, CostComponentFull } from '../calculator.types';
import { PK } from '@core/types/types.pk';
import { Calculate_FetchGetOrNull } from '../calculator.types';
import { CalculationError } from '../calculator';
import { DBBICost } from '@core/schemas/db/schema.db.bi';
import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { DBOHRate, DBElementsScrapRate } from '@core/schemas/db/schema.db.common';
import {
  DBPackingStandardCost,
  DBPackingNonstandardCost,
  DBPackagingStandardCost,
  DBPackagingNonstandardCost,
} from '@core/schemas/db/schema.db.packaging';
import { DBProdCost } from '@core/schemas/db/schema.db.prod';
import { CostComponent } from '@core/types/types.costComponent';
import { round2, round2relaxed } from '../calculator.util';

type SubElementScrapRate = Pick<DBElementsScrapRate, 'year' | 'scrap_rate_percent'>;
type SubOverheadRate = Pick<
  DBOHRate,
  'year' | 'oh_rate_percent' | 'planning_oh_prepacks_dkk' | 'planning_oh_finished_goods_dkk'
>;
type SubPackingStandardCost = Pick<
  DBPackingStandardCost,
  'fmc1_quantity' | 'fmc2_quantity' | 'packing_key' | 'fmc1_cost' | 'fmc2_cost'
>;
type SubPackingNonstandardCost = Pick<
  DBPackingNonstandardCost,
  'packing_type' | 'fmc1_quantity' | 'fmc2_quantity' | 'fmc1_cost' | 'fmc2_cost'
>;
type SubPackagingStandardCost = Pick<DBPackagingStandardCost, 'fmc1_cost' | 'fmc2_cost'>;
type SubPackagingNonstandardCost = Pick<
  DBPackagingNonstandardCost,
  'fmc1_cost_plus_scrap' | 'fmc2_cost_plus_scrap'
>;
type SubElementCost = Pick<DBElemCost, 'total_cost' | 'cost_comp'>;

type SubOtherCost = Pick<DBProdCost, 'fmc_eu_cost' | 'fmc_us_cost'>;
type SubBiCost = Pick<DBBICost, 'cost' | 'fmc_region'>;

export async function calculate_CC_FinishedGoodOverhead(props: {
  input: {
    ppos: CalculatorCommonPPO;
    overheadTypeEU: number | undefined;
    overheadTypeUS: number | undefined;
    scrapTypeEU: number | undefined;
    scrapTypeUS: number | undefined;
    fmcYear: number;
    copyPpoToCost?: boolean;
    productId: number;
    revision: number;
  };
  cache?: {
    biCosts?: SubBiCost[];
    otherCost: { fmc1cost: number; fmc2cost: number };
    elementCosts?: SubElementCost[];
    overheadRateEU?: SubOverheadRate;
    overheadRateUS?: SubOverheadRate;
    elementsScrapRateEU?: SubElementScrapRate;
    elementsScrapRateUS?: SubElementScrapRate;
    packingNonstandardCosts?: SubPackingNonstandardCost[];
    packingStandardCosts?: SubPackingStandardCost[];
    packagingStandardCosts?: SubPackagingStandardCost[];
    packagingNonstandardCosts?: SubPackagingNonstandardCost[];
    cost?: { use: boolean; values: { fmc1cost: number | undefined; fmc2cost: number | undefined } };
  };
  query?: {
    getOrNull?: Calculate_FetchGetOrNull;
    query?: Calculate_FetchQuery;
  };
}): Promise<CostComponentFull> {
  const { cache, input, query } = props;

  if (cache?.cost?.use) {
    if (cache.cost.values.fmc1cost === undefined) {
      throw new CalculationError({
        region: 'EU',
        message: 'Cost was provided but fmc1cost was missing',
      });
    }

    if (cache.cost.values.fmc2cost === undefined) {
      throw new CalculationError({
        region: 'US',
        message: 'Cost was provided but fmc2cost was missing',
      });
    }

    return {
      fmc1cost: round2(cache.cost.values.fmc1cost),
      fmc2cost: round2(cache.cost.values.fmc2cost),
      fmc1ppo: round2relaxed(input.ppos?.FMC1),
      fmc2ppo: round2relaxed(input.ppos?.FMC2),
      fmc1variance: C.Variance(input.ppos?.FMC1, cache.cost.values.fmc1cost),
      fmc2variance: C.Variance(input.ppos?.FMC2, cache.cost.values.fmc2cost),
    };
  }

  if (input.copyPpoToCost) {
    return {
      fmc1cost: round2relaxed(input.ppos?.FMC1),
      fmc2cost: round2relaxed(input.ppos?.FMC2),
      fmc1ppo: round2relaxed(input.ppos?.FMC1),
      fmc2ppo: round2relaxed(input.ppos?.FMC2),
      fmc1variance: round2relaxed(input.ppos?.FMC1 === undefined ? undefined : 0),
      fmc2variance: round2relaxed(input.ppos?.FMC2 === undefined ? undefined : 0),
    };
  }

  const [
    elementsScrapRateEU,
    elementsScrapRateUS,
    overheadRateEU,
    overheadRateUS,
    packingNonstandardCosts,
    packingStandardCosts,
    packagingStandardCosts,
    packagingNonstandardCosts,
    elementCosts,
    otherCosts,
    biCosts,
  ] = await Promise.all([
    input.scrapTypeEU === undefined
      ? undefined
      : useCacheOrFetchSingle<DBElementsScrapRate, SubElementScrapRate>({
          cachedItem: cache?.elementsScrapRateEU,
          fetchQuery: query?.getOrNull,
          fetchQueryArgs: {
            pk: PK.ElementsScrapRate,
            query: { year: input.fmcYear, type: input.scrapTypeEU },
          },
          dataTypeDescription: 'overhead rate',
          strict: true,
        }),
    input.scrapTypeUS === undefined
      ? undefined
      : useCacheOrFetchSingle<DBElementsScrapRate, SubElementScrapRate>({
          cachedItem: cache?.elementsScrapRateUS,
          fetchQuery: query?.getOrNull,
          fetchQueryArgs: {
            pk: PK.ElementsScrapRate,
            query: { year: input.fmcYear, type: input.scrapTypeUS },
          },
          dataTypeDescription: 'overhead rate',
          strict: true,
        }),
    input.overheadTypeEU === undefined
      ? undefined
      : useCacheOrFetchSingle<DBOHRate, SubOverheadRate>({
          cachedItem: cache?.overheadRateEU,
          fetchQuery: query?.getOrNull,
          fetchQueryArgs: {
            pk: PK.OHRate,
            query: { year: input.fmcYear, type: input.overheadTypeEU },
          },
          dataTypeDescription: 'overhead rate',
          strict: true,
        }),
    input.overheadTypeUS === undefined
      ? undefined
      : useCacheOrFetchSingle<DBOHRate, SubOverheadRate>({
          cachedItem: cache?.overheadRateUS,
          fetchQuery: query?.getOrNull,
          fetchQueryArgs: {
            pk: PK.OHRate,
            query: { year: input.fmcYear, type: input.overheadTypeUS },
          },
          dataTypeDescription: 'overhead rate',
          strict: true,
        }),
    useCacheOrFetchQuery<DBPackingNonstandardCost, SubPackingNonstandardCost>({
      cachedItems: cache?.packingNonstandardCosts,
      fetchQuery: query?.query,
      fetchQueryArgs: {
        pk: PK.PackingNonstandardCost,
        query: { product_id: input.productId, revision: input.revision },
      },
      dataTypeDescription: 'packing nonstandard costs',
    }),
    useCacheOrFetchQuery<DBPackingStandardCost, SubPackingStandardCost>({
      cachedItems: cache?.packingStandardCosts,
      fetchQuery: query?.query,
      fetchQueryArgs: {
        pk: PK.PackingStandardCost,
        query: { product_id: input.productId, revision: input.revision },
      },
      dataTypeDescription: 'packing standard costs',
    }),
    useCacheOrFetchQuery<DBPackagingStandardCost, SubPackagingStandardCost>({
      cachedItems: cache?.packagingStandardCosts,
      fetchQuery: query?.query,
      fetchQueryArgs: {
        pk: PK.PackagingStandardCost,
        query: { product_id: input.productId, revision: input.revision },
      },
      dataTypeDescription: 'packaging standard costs',
    }),
    useCacheOrFetchQuery<DBPackagingNonstandardCost, SubPackagingNonstandardCost>({
      cachedItems: cache?.packagingNonstandardCosts,
      fetchQuery: query?.query,
      fetchQueryArgs: {
        pk: PK.PackagingNonstandardCost,
        query: { product_id: input.productId, revision: input.revision },
      },
      dataTypeDescription: 'packaging nonstandard costs',
    }),
    useCacheOrFetchQuery<DBElemCost, SubElementCost>({
      cachedItems: cache?.elementCosts,
      fetchQuery: query?.query,
      fetchQueryArgs: {
        pk: PK.ElemCost,
        query: { product_id: input.productId, revision: input.revision },
      },
      dataTypeDescription: 'element costs',
    }),
    useCacheOrFetchSingle<DBProdCost, SubOtherCost>({
      cachedItem: cache?.otherCost
        ? { fmc_eu_cost: cache.otherCost.fmc1cost, fmc_us_cost: cache.otherCost.fmc2cost }
        : undefined,
      fetchQuery: query?.getOrNull,
      fetchQueryArgs: {
        pk: PK.ProdCost,
        query: {
          product_id: input.productId,
          revision: input.revision,
          cost_component: CostComponent.Others,
        },
      },
      dataTypeDescription: 'others cost',
      strict: true,
    }),
    useCacheOrFetchQuery<DBBICost, SubBiCost>({
      cachedItems: cache?.biCosts,
      fetchQuery: query?.query,
      fetchQueryArgs: {
        pk: PK.BICost,
        query: { product_id: input.productId, revision: input.revision },
      },
      dataTypeDescription: 'building instructions costs',
    }),
  ]);

  if (elementsScrapRateEU === undefined) {
    throw new CalculationError({
      message: 'Scrap type was undefined and cache was not provided',
      region: 'EU',
    });
  }
  if (elementsScrapRateUS === undefined) {
    throw new CalculationError({
      message: 'Scrap type was undefined and cache was not provided',
      region: 'US',
    });
  }
  if (overheadRateEU === undefined) {
    throw new CalculationError({
      message: 'Overhead type was undefined and cache was not provided',
      region: 'EU',
    });
  }
  if (overheadRateUS === undefined) {
    throw new CalculationError({
      message: 'Overhead type was undefined and cache was not provided',
      region: 'US',
    });
  }

  const costCCLEGOElements = Calculator.Elements.CC_Elements.Sum(
    elementCosts,
    CostComponent.LEGOElements,
  );
  const costCCPurchasedElements = Calculator.Elements.CC_Elements.Sum(
    elementCosts,
    CostComponent.PurchasedElements,
  );
  const { fmc1cost: fmc1costCCElementsScrap, fmc2cost: fmc2costCCElementsScrap } =
    Calculator.Elements.CC_ElementsScrap.Sum({
      elements: elementCosts,
      scrapRatePercentEU: elementsScrapRateEU.scrap_rate_percent,
      scrapRatePercentUS: elementsScrapRateUS.scrap_rate_percent,
    });

  const { fmc1cost: fmc1costCCPacking, fmc2cost: fmc2costCCPacking } =
    Calculator.Pack.CC_Packing.Sum(packingStandardCosts, packingNonstandardCosts);

  const { fmc1cost: fmc1costCCPackaging, fmc2cost: fmc2costCCPackaging } =
    Calculator.Pack.CC_Packaging.Sum(packagingStandardCosts, packagingNonstandardCosts);
  const { fmc1cost: fmc1costCCInstructions, fmc2cost: fmc2costCCInstructions } =
    Calculator.BI.CC_Instructions.Sum(biCosts?.map((b) => ({ ...b, fmcRegion: b.fmc_region })));
  const { fmc1cost: fmc1costCCOthers, fmc2cost: fmc2costCCOthers } = Calculator.CC_Others.Sum({
    cost: otherCosts,
  });

  const fmc1sum = C.Sum(
    costCCLEGOElements,
    costCCPurchasedElements,
    fmc1costCCElementsScrap,
    fmc1costCCPacking,
    fmc1costCCPackaging,
    fmc1costCCInstructions,
    fmc1costCCOthers,
  );

  const fmc2sum = C.Sum(
    costCCLEGOElements,
    costCCPurchasedElements,
    fmc2costCCElementsScrap,
    fmc2costCCPacking,
    fmc2costCCPackaging,
    fmc2costCCInstructions,
    fmc2costCCOthers,
  );

  let fmc1cost = C.Sum(
    C.Product(overheadRateEU.oh_rate_percent / 100, fmc1sum),
    overheadRateEU.planning_oh_finished_goods_dkk,
  );
  let fmc2cost = C.Sum(
    C.Product(overheadRateUS.oh_rate_percent / 100, fmc2sum),
    overheadRateUS.planning_oh_finished_goods_dkk,
  );

  const { fmc1: fmc1numberOfPrepacks, fmc2: fmc2numberOfPrepacks } =
    Calculator.Pack.Packing.NumberOfPrepacks(packingStandardCosts, packingNonstandardCosts);

  if (input.overheadTypeEU !== 3) {
    fmc1cost = C.Sum(
      fmc1cost,
      C.Product(fmc1numberOfPrepacks, overheadRateEU.planning_oh_prepacks_dkk),
    );
  }

  if (input.overheadTypeUS !== 3) {
    fmc2cost = C.Sum(
      fmc2cost,
      C.Product(fmc2numberOfPrepacks, overheadRateUS.planning_oh_prepacks_dkk),
    );
  }

  return {
    fmc1cost: round2relaxed(fmc1cost),
    fmc2cost: round2relaxed(fmc2cost),
    fmc1ppo: round2relaxed(input.ppos?.FMC1),
    fmc2ppo: round2relaxed(input.ppos?.FMC2),
    fmc1variance: round2relaxed(C.Variance(input.ppos?.FMC1, fmc1cost)),
    fmc2variance: round2relaxed(C.Variance(input.ppos?.FMC2, fmc2cost)),
  };
}
