import {
  DBPackagingAddonRate,
  DBPackagingFoilRate,
  DBPackagingRate,
  DBPackagingScrapRate,
  DBPackingNonstandardRate,
  DBPackingStandardCost,
  DBPackingStandardFinalPackRate,
  DBPackingStandardPrepackRate,
} from '@core/schemas/db/schema.db.packaging';
import { PackSource } from '@core/types/types.packaging';

export function Calculator_Packaging_StandardCost_PackagingFoilRates_filter(input: {
  year: number;
}): Partial<DBPackagingFoilRate> {
  return {
    year: input.year,
  };
}

export function Calculator_Packaging_StandardCost_PackingStandardCosts_filter(input: {
  productId: number;
  revision: number;
}): Partial<DBPackingStandardCost> {
  return {
    product_id: input.productId,
    revision: input.revision,
  };
}

export function Calculator_Packaging_StandardCost_PackagingAddOnRates_filter(input: {
  year: number;
}): Partial<DBPackagingAddonRate> {
  return { year: input.year };
}

export function Calculator_Packaging_NonstandardCost_PackagingScrapRates_filter(input: {
  year: number;
}): Partial<DBPackagingScrapRate> {
  return { year: input.year };
}

export function Calculator_Packaging_PackagingRates_filter(input: {
  year: number;
  packagingSize: number;
}): Partial<DBPackagingRate> {
  return { year: input.year, packaging_size: input.packagingSize };
}

export function Calculator_Packing_NonstandardCost_PackingNonstandardRates_filter(input: {
  year: number;
}): Partial<DBPackingNonstandardRate> {
  return { year: input.year };
}

export function Calculator_Packing_StandardCost_PackingStandardFinalPackRates_filter(input: {
  year: number;
}): Partial<DBPackingStandardFinalPackRate> {
  return { year: input.year };
}

export function Calculator_Packing_StandardCost_PackingStandardPrepackRates_filter(input: {
  year: number;
}): Partial<DBPackingStandardPrepackRate> {
  return { year: input.year };
}
