import { GREEN, RED } from '@frontend/common/lib/common.styles';
import { prettifyTimestamp } from '@frontend/common/lib/common.util';
import { TableColumn } from '@frontend/table/table';
import { DBAMLog } from '@core/schemas/db/schema.db.common';
import { ADGroup, ADGroupMember, UserWithGroups } from '@core/schemas/schema.common';
import { Link } from '@lego/klik-ui';
import { YearChangeStatus } from '@core/schemas/db/schema.db.yearChange';
import { TbExternalLink } from 'react-icons/tb';
import { pageRoutes } from '@frontend/common/lib/definitions';
import { FaCheck, FaCheckDouble, FaExclamation, FaInfo, FaQuestion } from 'react-icons/fa';
import { uppercaseFirstLetter } from '@core/util/util.uppercaseFirstLetter';
import { YearChangeOverviewRecord } from '@core/schemas/types/schemas.types';
import { IoPersonRemove } from 'react-icons/io5';

export function getAMLogsForGroupColumns(
  getName: (email: string | undefined) => string,
): TableColumn<DBAMLog>[] {
  return [
    {
      dataIndex: 'timestamp',
      title: 'Timestamp',
      sorted: 'descending',
      format: (v) => prettifyTimestamp(v),
    },
    {
      dataIndex: '',
      title: 'Description',
      width: 'auto',
      format: (v, r) =>
        `${r.action_by} ${r.action_type === 'add' ? 'added' : 'removed'} ${r.action_on}`,
      render: (_v, r) => (
        <span>
          {getName(r.action_by)}{' '}
          {r.action_type === 'add' ? (
            <span style={{ color: GREEN }}>added</span>
          ) : (
            <span style={{ color: RED }}>removed</span>
          )}{' '}
          {getName(r.action_on)}
        </span>
      ),
    },
  ];
}

export function getAMLogsColumns(
  groups: ADGroup[],
  getName: (email: string | undefined) => string,
): TableColumn<DBAMLog>[] {
  return [
    {
      dataIndex: 'timestamp',
      title: 'Timestamp',
      format: (v) => prettifyTimestamp(v),
      sorted: 'descending',
    },
    {
      dataIndex: 'group_id',
      title: 'Group',
      format: (v) => groups.find((g) => g.id === v)?.name || '',
    },
    {
      dataIndex: '',
      title: 'Description',
      width: 'auto',
      format: (v, r) =>
        `${r.action_by} ${r.action_type === 'add' ? 'added' : 'removed'} ${r.action_on}`,
      render: (_v, r) => (
        <span>
          {getName(r.action_by)}{' '}
          {r.action_type === 'add' ? (
            <span style={{ color: GREEN }}>added</span>
          ) : (
            <span style={{ color: RED }}>removed</span>
          )}
          {' you'}
        </span>
      ),
    },
  ];
}

export const ADGroupColumns: TableColumn<ADGroup>[] = [
  { dataIndex: 'id', title: 'ID' },
  {
    dataIndex: 'name',
    title: 'Group name',
    sorted: 'ascending',
    sorter: (a, b) => {
      if (a.name.includes('Administration') && !b.name.includes('Administration')) {
        return 1;
      }
      if (!a.name.includes('Administration') && b.name.includes('Administration')) {
        return -1;
      }
      if (a.name.includes('DataAdmin') && !b.name.includes('DataAdmin')) {
        return 1;
      }
      if (!a.name.includes('DataAdmin') && b.name.includes('DataAdmin')) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    },
  },
  { dataIndex: 'description', title: 'Description', width: 'auto' },
];

export const ADGroupMemberColumns: TableColumn<ADGroupMember>[] = [
  { dataIndex: 'name', title: 'Name' },
  { dataIndex: 'email', title: 'Email', width: 'auto' },
];

export function getAllUsersColumns(
  getName: (email: string | undefined) => string,
): TableColumn<UserWithGroups>[] {
  return [
    {
      title: 'Name',
      dataIndex: 'email',
      id: 'name',
      render: (v: string) => getName(v),
      sorted: 'ascending',
    },
    { title: 'Email', dataIndex: 'email' },
    {
      title: 'Groups',
      dataIndex: 'groups',
      format: (v: ADGroup[]) =>
        v
          .map((g) => g.name)
          .sort()
          .join(', '),
    },
  ];
}

function StatusCell(props: {
  status: YearChangeStatus;
  revision: number | undefined;
  productId: number;
  path: string;
}) {
  const { status, revision, path, productId } = props;

  if (status === YearChangeStatus.Uknonwn) {
    return <StatusIcon status={status} />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Link
        href={`${path}?productId=${productId}&revision=${revision}`}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'flex', alignItems: 'center', lineHeight: 0 }}
        onClick={(e) => e.stopPropagation()}
      >
        {props.revision}
        <TbExternalLink style={{ marginLeft: 4, marginTop: -2 }} />
      </Link>
      <div style={{ marginLeft: 4 }}>
        <StatusIcon status={props.status} />
      </div>
    </div>
  );
}

export function StatusIcon(props: { status: YearChangeStatus }) {
  if (props.status === YearChangeStatus.Uknonwn) {
    return <FaQuestion style={{ color: '#4d4d4d' }} title="No approved revision in current year" />;
  }
  if (props.status === YearChangeStatus.Identified) {
    return (
      <FaInfo
        style={{ color: '#0084ff', marginTop: -1 }}
        title="Revision in current year indentified to be used for next year's revision"
      />
    );
  }
  if (props.status === YearChangeStatus.IdentifiedNotApproved) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>(</div>
        <FaInfo
          style={{ color: '#0084ff', marginTop: -1 }}
          title="Non-approved revision in current year indentified to be used for next year's revision"
        />
        <div>)</div>
      </div>
    );
  }
  if (props.status === YearChangeStatus.Error) {
    return <FaExclamation style={{ color: RED }} title="Updated revision contains errors" />;
  }
  if (props.status === YearChangeStatus.Updated) {
    return <FaCheck style={{ color: 'orange' }} title="Updated revision created in new year" />;
  }
  if (props.status === YearChangeStatus.Approved) {
    return <FaCheckDouble style={{ color: GREEN }} title="Revision for new year approved " />;
  }

  return null;
}

function ProductIdCell(props: YearChangeOverviewRecord) {
  const statuses = [
    props.elements.status,
    props.packaging.status,
    props.bi.status,
    props.finalcosts.status,
  ];

  let Icon = null;

  if (statuses.includes(YearChangeStatus.Error)) {
    Icon = <StatusIcon status={YearChangeStatus.Error} />;
  } else if (statuses.includes(YearChangeStatus.Uknonwn)) {
    Icon = <StatusIcon status={YearChangeStatus.Uknonwn} />;
  } else if (statuses.includes(YearChangeStatus.Identified)) {
    Icon = <StatusIcon status={YearChangeStatus.Identified} />;
  } else if (statuses.includes(YearChangeStatus.Updated)) {
    Icon = <StatusIcon status={YearChangeStatus.Updated} />;
  } else if (statuses.includes(YearChangeStatus.Approved)) {
    Icon = <StatusIcon status={YearChangeStatus.Approved} />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
      <div>{Icon}</div>
      <div>{props.productId}</div>
    </div>
  );
}

export const YearChangeOverviewColumns: TableColumn<YearChangeOverviewRecord>[] = [
  {
    title: 'Product ID',
    dataIndex: 'productId',
    sorted: 'ascending',
    render: (_v, r) => <ProductIdCell {...r} />,
  },
  {
    title: 'Elements',
    dataIndex: 'elements.revision',
    align: 'center',
    format: (v: number, r) =>
      r.elements.status === YearChangeStatus.Uknonwn
        ? YearChangeStatus.Uknonwn
        : v + '-' + r.elements.status,
    render: (v: number, r) => (
      <StatusCell
        status={r.elements.status}
        productId={r.productId}
        revision={v}
        path={pageRoutes.Elements}
      />
    ),
  },

  {
    title: 'Packaging',
    align: 'center',
    dataIndex: 'packaging.revision',
    format: (v: number, r) =>
      r.packaging.status === YearChangeStatus.Uknonwn
        ? YearChangeStatus.Uknonwn
        : v + '-' + r.packaging.status,
    render: (v: number, r) => (
      <StatusCell
        status={r.packaging.status}
        productId={r.productId}
        revision={v}
        path={pageRoutes.Packaging}
      />
    ),
  },
  {
    title: 'Instructions',
    align: 'center',
    dataIndex: 'bi.revision',
    format: (v: number, r) =>
      r.bi.status === YearChangeStatus.Uknonwn ? YearChangeStatus.Uknonwn : v + '-' + r.bi.status,
    render: (v: number, r) => (
      <StatusCell
        status={r.bi.status}
        productId={r.productId}
        revision={v}
        path={pageRoutes.Instructions}
      />
    ),
  },
  {
    title: 'Final costs',
    align: 'center',
    dataIndex: 'finalcosts.revision',
    format: (v: number, r) =>
      r.finalcosts.status === YearChangeStatus.Uknonwn
        ? YearChangeStatus.Uknonwn
        : v + '-' + r.finalcosts.status,
    render: (v: number, r) => (
      <StatusCell
        status={r.finalcosts.status}
        productId={r.productId}
        revision={v}
        path={pageRoutes['Final costs']}
      />
    ),
  },
];

export const YearChangeOverviewExportColumns: TableColumn<YearChangeOverviewRecord>[] = [
  {
    title: 'Product ID',
    dataIndex: 'productId',
    sorted: 'ascending',
    render: (_v, r) => <ProductIdCell {...r} />,
  },
  {
    title: 'Elements revision',
    dataIndex: 'elements.revision',
    format: (v) => v,
  },
  {
    title: 'Elements status',
    dataIndex: 'elements.status',
    format: (v: YearChangeStatus) => uppercaseFirstLetter(v),
  },
  {
    title: 'Packaging revision',
    dataIndex: 'packaging.revision',
    format: (v) => v,
  },
  {
    title: 'Packaging status',
    dataIndex: 'packaging.status',
    format: (v: YearChangeStatus) => uppercaseFirstLetter(v),
  },
  {
    title: 'BI revision',
    dataIndex: 'bi.revision',
    format: (v) => v,
  },
  {
    title: 'BI status',
    dataIndex: 'bi.status',
    format: (v: YearChangeStatus) => uppercaseFirstLetter(v),
  },
  {
    title: 'Final costs revision',
    dataIndex: 'finalcosts.revision',
    format: (v) => v,
  },
  {
    title: 'Final costs status',
    dataIndex: 'finalcosts.status',
    format: (v: YearChangeStatus) => uppercaseFirstLetter(v),
  },
];

export const ADGroupsColumns: TableColumn<ADGroup>[] = [
  { title: 'ID', dataIndex: 'id' },
  { title: 'Name', dataIndex: 'name' },
  { title: 'Description', dataIndex: 'description' },
];

export function getGroupMemberColumns(input: {
  onRemoveMember: (email: string) => void;
  isWorking: boolean;
}): TableColumn<ADGroupMember>[] {
  const { isWorking, onRemoveMember } = input;

  return [
    { dataIndex: 'name', title: 'Name', sorted: 'ascending' },
    { dataIndex: 'email', title: 'Email', width: 'auto' },
    {
      id: 'action', // need this because missingGroupIds is already used to identity above column
      title: '',
      dataIndex: 'email',
      render: (email: string) => (
        <IoPersonRemove
          style={{ cursor: 'pointer', color: RED }}
          onClick={() => {
            if (isWorking) {
              return;
            }
            onRemoveMember(email);
          }}
        />
      ),
    },
  ];
}
