import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { ProdHeadCoreSchema } from '@core/schemas/db/schema.db.prod';
import { RegionEnum } from '@core/schemas/schema.common';
import { PK } from '@core/types/types.pk';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { z } from 'zod';
import {
  FoilPackingKey,
  PackSource,
  PackagingAddOnKey,
  PackagingPackingKey,
  PackingKeyId,
  PackingNonstandardKey,
} from '@core/types/types.packaging';

/**
 * DBProdHeadPack
 */
export type DBProdHeadPack = z.infer<typeof DBProdHeadPackSchema>;
export const DBProdHeadPackSchema = ProdHeadCoreSchema.extend({
  PK: z.literal(PK.ProdHeadPack),
  estimation_code: z.string().optional(),
  alternative_code: z.string().optional(),
  packaging_size: z.number().int().optional(),
  box_filling_degree_pct: z.number(),
  source_eu: z
    .nativeEnum(PackSource)
    .optional()
    .describe(createDescription({ supportLov: true })),
  source_us: z
    .nativeEnum(PackSource)
    .optional()
    .describe(createDescription({ supportLov: true })),
  packing_region_eu: RegionEnum.optional(),
  packing_region_us: RegionEnum.optional(),
  sales_region_eu: RegionEnum,
  sales_region_us: RegionEnum,
  embossing: z.boolean(),
  hotfoil: z.boolean(),
  uv_lacquer: z.boolean(),
  partial_uv_lacquer: z.boolean(),
  window_cutout: z.boolean(),
  window_foil: z.boolean(),
  model_bag: z.boolean(),
  currency: z.string().describe(createDescription({ supportLov: true })),
  note: z.string(),
});

/**
 * DBPackSource
 */
export type DBPackSource = z.infer<typeof DBPackSourceSchema>;
export const DBPackSourceSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackSource),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  description: z.string(),
});

/**
 * DBPackingStandardPrepackRate
 */
export type DBPackingStandardPrepackRate = z.infer<typeof DBPackingStandardPrepackRateSchema>;
export const DBPackingStandardPrepackRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackingStandardPrepackRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  packing_key: z.string().describe(createDescription({ isPartOfKey: true })),
  cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackingStandardFinalPackRate
 */
export type DBPackingStandardFinalPackRate = z.infer<typeof DBPackingStandardFinalPackRateSchema>;
export const DBPackingStandardFinalPackRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackingStandardFinalPackRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packing_key: z.string().describe(createDescription({ isPartOfKey: true })),
  packaging_facing: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packaging_size: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packing_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  sales_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  fmc_quantity: z.number().int(),
  bun: z.string(),
  cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackingStandardCost
 */
export type DBPackingStandardCost = z.infer<typeof DBPackingStandardCostSchema>;
export const DBPackingStandardCostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackingStandardCost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packing_key: z.nativeEnum(PackingKeyId).describe(createDescription({ isPartOfKey: true })),
  fmc1_quantity: z.number().int(),
  fmc2_quantity: z.number().int(),
  bun: z.string(),
  fmc1_cost: z.number(), // should always be there
  fmc2_cost: z.number(), // should always be there
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackingNonstandardRate
 */
export type DBPackingNonstandardRate = z.infer<typeof DBPackingNonstandardRateSchema>;
export const DBPackingNonstandardRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackingNonstandardRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  packing_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  packing_type: z.string().describe(createDescription({ isPartOfKey: true })),
  operator_rate: z.number(),
  machine_rate: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackingNonstandardCost
 */
export type DBPackingNonstandardCost = z.infer<typeof DBPackingNonstandardCostSchema>;
export const DBPackingNonstandardCostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackingNonstandardCost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  row_id: z.string().describe(createDescription({ isPartOfKey: true })),
  packing_type: z
    .nativeEnum(PackingNonstandardKey)
    .or(z.literal(''))
    .describe(createDescription({ supportLov: true })),
  description: z.string(),
  fmc1_number_of_operators: z.number(),
  fmc2_number_of_operators: z.number(),
  fmc1_machine_time: z.number(),
  fmc2_machine_time: z.number(),
  fmc1_quantity: z.number(),
  fmc2_quantity: z.number(),
  bun: z.string(),
  fmc1_cost: z.number(),
  fmc2_cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBAddonRate
 */
export type DBPackagingAddonRate = z.infer<typeof DBPackagingAddonRateSchema>;
export const DBPackagingAddonRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackagingAddOnRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packing_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  packing_key: z.nativeEnum(PackagingAddOnKey).describe(createDescription({ isPartOfKey: true })),
  rate_percent: z.number(),
});

/**
 * DBPackagingStandardCost
 */
export type DBPackagingStandardCost = z.infer<typeof DBPackagingStandardCostSchema>;
export const DBPackagingStandardCostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackagingStandardCost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packing_key: z.string().describe(createDescription({ isPartOfKey: true })),
  description: z.string(),
  fmc1_quantity: z.number(),
  fmc2_quantity: z.number(),
  fmc1_cost: z.number(),
  fmc2_cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
  custom: z.boolean(),
});

/**
 * DBPackagingNonstandardCost
 */
export type DBPackagingNonstandardCost = z.infer<typeof DBPackagingNonstandardCostSchema>;
export const DBPackagingNonstandardCostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackagingNonstandardCost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  row_id: z.string().describe(createDescription({ isPartOfKey: true })),
  description: z.string(),
  fmc1_quantity: z.number(),
  fmc2_quantity: z.number(),
  fmc1_material: z.number().int().optional(),
  fmc2_material: z.number().int().optional(),
  fmc1_material_description: z.string().optional(),
  fmc2_material_description: z.string().optional(),
  fmc1_cost: z.number(),
  fmc2_cost: z.number(),
  fmc1_cost_plus_scrap: z.number(),
  fmc2_cost_plus_scrap: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackagingRate
 */
export type DBPackagingRate = z.infer<typeof DBPackagingRateSchema>;
export const DBPackagingRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackagingRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  packaging_size: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  packing_key: z.nativeEnum(PackagingPackingKey).describe(createDescription({ isPartOfKey: true })),
  packing_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  sales_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  fmc_quantity: z.number(),
  bun: z.string(),
  cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackagingScrapRate
 */
export type DBPackagingScrapRate = z.infer<typeof DBPackagingScrapRateSchema>;
export const DBPackagingScrapRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackagingScrapRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  packing_region: RegionEnum.describe(createDescription({ isPartOfKey: true })),
  rate_percent: z.number(),
});

/**
 * DBPackagingFoilRate
 */
export type DBPackagingFoilRate = z.infer<typeof DBPackagingFoilRateSchema>;
export const DBPackagingFoilRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackagingFoilRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  source: z
    .nativeEnum(PackSource)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  model_bag: z.boolean().describe(createDescription({ isPartOfKey: true })),
  packing_key: z.nativeEnum(FoilPackingKey).describe(createDescription({ isPartOfKey: true })),
  rate: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBPackingNonstandardKey
 */
export type DBPackingNonstandardKey = z.infer<typeof DBPackingNonstandardKeySchema>;
export const DBPackingNonstandardKeySchema = DBRecordSchema.extend({
  PK: z.literal(PK.PackingNonstandardKey),
  key: z
    .nativeEnum(PackingNonstandardKey)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  description: z.string(),
  is_final_pack: z.boolean(),
});
