import { z } from 'zod';
import { DBRecordSchema } from '@core/schemas/db/schema.db.common';
import { PK } from '@core/types/types.pk';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { IsoDateSchema } from '../schema.util';
import { CostComponent } from '@core/types/types.costComponent';

/**
 * ProdHeadCore
 */
export type ProdHeadCore = z.infer<typeof ProdHeadCoreSchema>;
export const ProdHeadCoreSchema = DBRecordSchema.extend({
  PK: z.enum([PK.ProdHeadElem, PK.ProdHeadFC, PK.ProdHeadBI, PK.ProdHeadPack]),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  year: z.number().int(),
  created_by: z.string(),
  created_timestamp: IsoDateSchema,
  changed_by: z.string().optional(),
  changed_timestamp: IsoDateSchema.optional(),
  approved_by: z.string().optional(),
  approved_timestamp: IsoDateSchema.optional(),
  approval_note: z.string().optional(),
  rejected_by: z.string().optional(),
  rejected_timestamp: IsoDateSchema.optional(),
  rejection_note: z.string().optional(),
  rejection_lock: z.boolean(),
  errors: z.string(),
  change_lock_by: z.string().optional(),
  change_lock_timestamp: IsoDateSchema.optional(),
});

/**
 * DBProdParams
 */
export type DBProdParams = z.infer<typeof DBProdParamsSchema>;
export const DBProdParamsSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ProdParams),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  bi_qr_code: z.boolean(),
  created_timestamp: IsoDateSchema,
  created_by: z.string(),
  changed_timestamp: IsoDateSchema.optional(),
  changed_by: z.string().optional(),
  scrap_type_eu: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
  scrap_type_us: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
  overhead_type_eu: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
  overhead_type_us: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
});

/**
 * DBProdCost
 */
export type DBProdCost = z.infer<typeof DBProdCostSchema>;
export const DBProdCostSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ProdCost),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  cost_component: z
    .nativeEnum(CostComponent)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  fmc_eu_cost: z.number(),
  fmc_us_cost: z.number(),
  currency: z.string().describe(createDescription({ supportLov: true })),
  created_timestamp: IsoDateSchema.optional(),
  created_by: z.string(),
  changed_timestamp: IsoDateSchema.optional(),
  changed_by: z.string().optional(),
});

/**
 * DBProdPPO
 * Revision is coupled with the final cost revision - new/current/active prod PPOs uses revision 0
 * and on final cost revision approve, all '0' prod PPOs for that product are copied but with the approved revision number instead
 */
export type DBProdPPO = z.infer<typeof DBProdPPOSchema>;
export const DBProdPPOSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ProdPPO),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  revision: z.number().describe(createDescription({ isPartOfKey: true })),
  cost_component: z
    .nativeEnum(CostComponent)
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  fmc_eu_cost: z.number(),
  fmc_us_cost: z.number(),
  created_timestamp: IsoDateSchema.optional(),
  created_by: z.string(),
  changed_timestamp: IsoDateSchema.optional(),
  changed_by: z.string().optional(),
  currency: z.string().describe(createDescription({ supportLov: true })),
});

/**
 * DBProdHeadFC
 */
export type DBProdHeadFC = z.infer<typeof DBProdHeadFCSchema>;
export const DBProdHeadFCSchema = ProdHeadCoreSchema.extend({
  PK: z.literal(PK.ProdHeadFC),
  revision_elements: z.number().int().optional(),
  revision_packaging: z.number().int().optional(),
  revision_bi: z.number().int().optional(),
  scrap_type_eu: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
  scrap_type_us: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
  overhead_type_eu: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
  overhead_type_us: z
    .number()
    .int()
    .optional()
    .describe(createDescription({ supportLov: true })),
});

/**
 * DBProdExtra
 */
export enum ProdExtraType {
  Quantity = 'QUANTITY',
}
export type DBProdExtra = z.infer<typeof DBProdExtraSchema>;
export const DBProdExtraSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ProdExtra),
  product_id: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  type: z.nativeEnum(ProdExtraType).describe(createDescription({ isPartOfKey: true })), // potential enum going forward
  timestamp: IsoDateSchema.describe(createDescription({ isPartOfKey: true })),
  fmc1_value: z.number().optional(),
  fmc1_timestamp: IsoDateSchema.optional(),
  fmc2_value: z.number().optional(),
  fmc2_timestamp: IsoDateSchema.optional(),
});
