import { GeneralPage } from '@frontend/common/components/GeneralPage';
import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { FinalCostsProductHeader } from './FinalCostsProductHeader';
import { useContext, useEffect, useMemo } from 'react';
import {
  ConfigContext,
  LoadingOverlayContext,
  ProductIndexContext,
  UserDataContext,
} from '@frontend/common/lib/contexts';
import { FinalCostsDetailedCosts } from './FinalCostsDetailedCosts';
import { NCAttachments } from '@frontend/common/components/NCAttachments';
import { useScrapAndOH } from '../lib/useScrapAndOH';
import { FinalCostsOverview } from './FinalCostsOverview';
import { useOthersCostsOverviewRow } from '../lib/useOthersCostsOverviewRow';
import { PK } from '@core/types/types.pk';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { useFinishedGoodsOverheadCostsOverviewRow } from '../lib/useFinishedGoodsOverheadCostsOverviewRow';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import {
  FinalCostsGetEndpoint,
  FinalCostsNewGetEndpoint,
  FinalCostsSaveEndpoint,
  FinalCostsSaveEndpointInput,
} from '@core/schemas/endpoint/schema.endpoint.finalCosts';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  accessIsReadOnly,
  isRevisionReadOnly,
  showErrorToast,
  showInfoToast,
} from '@frontend/common/lib/functions';
import { Message } from '@frontend/common/lib/models';
import { CostComponent } from '@core/types/types.costComponent';
import { checkApproveFinalCost } from '@core/checks/check.approve.finalCost';
import { useLicenseCostsOverviewRow } from '../lib/useLicenseCostsOverviewRow';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';

export function FinalCostsPage() {
  const { setRevision, getProductIndex, setSuggestedProductId, clearProductIndex } =
    useContext(ProductIndexContext);
  const { config } = useContext(ConfigContext);
  const { email } = useContext(UserDataContext);

  const rev = getProductIndex().revision;

  const {
    data,
    loading,
    error,
    statusCode,
    queryFunction: refresh,
  } = useEndpoint({
    endpoint: FinalCostsGetEndpoint,
    input: {
      productId: convertProductIdTo8digits(getProductIndex().productId || 0),
      rev: rev === 'new' ? 1 : rev,
    },
    condition: !!getProductIndex().productId && rev !== 'new',
    errorHandling: { disable: true },
  });

  const {
    data: dataNew,
    loading: loadingNew,
    queryFunction: refreshNew,
  } = useEndpoint({
    endpoint: FinalCostsNewGetEndpoint,
    input: { productId: convertProductIdTo8digits(getProductIndex().productId || 0) },
    condition: !!getProductIndex().productId && rev === 'new',
    errorHandling: {
      header: 'Fetching initial final costs data',
      handlers: {
        '400': (message) => {
          const revisionExists = message === 'A revision already exists for this product';
          if (revisionExists) {
            setRevision(undefined);
          }
          (revisionExists ? showInfoToast : showErrorToast)(
            'Fetching initial final costs data',
            message,
          );
        },
      },
    },
  });

  const { setIsLoading } = useContext(LoadingOverlayContext);
  const { groupNames } = useContext(UserDataContext);

  useEffect(() => {
    if (error && getProductIndex().productId && getProductIndex().revision !== 'new') {
      if (statusCode === 404) {
        setSuggestedProductId(getProductIndex().productId);
        showInfoToast(
          'Revision does not exist',
          getProductIndex().revision
            ? `Product does not have a revision ${getProductIndex().revision}`
            : 'Product does not have a Final costs revision',
        );
        clearProductIndex();
      } else {
        showErrorToast('Fetching final costs data', error.error);
      }
    }
  }, [error, statusCode, clearProductIndex, setSuggestedProductId, getProductIndex]);

  const relevantData = useMemo(() => data || dataNew, [data, dataNew]);

  useEffect(() => {
    setIsLoading(loading || loadingNew);
    return () => setIsLoading(false);
  }, [loading, loadingNew, setIsLoading]);

  const { messages, clearAndAddMessages } = useMessages();

  function handleRevisionSelect(revision: number) {
    if (!getProductIndex().productId) return;

    setRevision(revision);
  }

  const {
    selectedOverheadRateEU,
    selectedOverheadRateUS,
    selectedScrapRateEU,
    selectedScrapRateUS,
    updateOverheadRate,
    updateScrapRate,
    overheadOptions,
    scrapOptions,
    invalidRates,
  } = useScrapAndOH({
    overheadTypeEU: relevantData?.overheadTypeEU,
    overheadTypeUS: relevantData?.overheadTypeUS,
    scrapTypeEU: relevantData?.scrapTypeEU,
    scrapTypeUS: relevantData?.scrapTypeUS,
    year: relevantData?.prodHead.year,
  });

  const useOtherCostsOverviewRowInput = useMemo(
    () => ({
      ppo: relevantData?.otherProductCosts.ppo[CostComponent.Others],
      cost: relevantData?.otherProductCosts.cost[CostComponent.Others],
    }),
    [relevantData?.otherProductCosts.ppo, relevantData?.otherProductCosts.cost],
  );

  const {
    cc_Others,
    costs: cc_Others_costs,
    updateCost: updateOthersCost,
  } = useOthersCostsOverviewRow(useOtherCostsOverviewRowInput);

  const useLicenseCostsOverviewRowInput = useMemo(
    () => ({
      ppo: relevantData?.otherProductCosts.ppo[CostComponent.License],
      cost: relevantData?.otherProductCosts.cost[CostComponent.License],
    }),
    [relevantData?.otherProductCosts.ppo, relevantData?.otherProductCosts.cost],
  );

  const {
    cc_License,
    costs: cc_License_costs,
    updateCost: updateLicenseCost,
  } = useLicenseCostsOverviewRow(useLicenseCostsOverviewRowInput);

  const readOnly = useMemo(
    () =>
      isRevisionReadOnly({ prodHead: relevantData?.prodHead, email, year: config.fmc_active_year }),
    [email, config.fmc_active_year, relevantData?.prodHead],
  );

  const useFinishedGoodsOverheadCostsOverviewRowInput = useMemo(
    () => ({
      productId: relevantData?.prodHead.product_id,
      revision: relevantData?.prodHead.revision,
      productCosts: {
        ppo: relevantData?.otherProductCosts.ppo[CostComponent.FinishedGoodOverhead],
        cost: relevantData?.otherProductCosts.cost[CostComponent.FinishedGoodOverhead],
      },
      readOnly,
      biCosts: relevantData?.biData?.biCosts || [],
      elementCosts: relevantData?.elementsData?.elementCosts || [],
      fmcYear: relevantData?.prodHead.year,
      otherCost: { fmc1cost: cc_Others.fmc1cost, fmc2cost: cc_Others.fmc2cost },
      packagingStandardCosts: relevantData?.packagingData?.packagingStandardCosts || [],
      packagingNonstandardCosts: relevantData?.packagingData?.packagingNonstandardCosts || [],
      packingNonstandardCosts: relevantData?.packagingData?.packingNonstandardCosts || [],
      overheadRateEU: selectedOverheadRateEU,
      overheadRateUS: selectedOverheadRateUS,
      elementsScrapRateEU: selectedScrapRateEU,
      elementsScrapRateUS: selectedScrapRateUS,
      packingStandardCosts: relevantData?.packagingData?.packingStandardCosts || [],
      invalidRates,
    }),
    [
      readOnly,
      relevantData?.prodHead.product_id,
      relevantData?.prodHead.revision,
      relevantData?.otherProductCosts,
      relevantData?.biData?.biCosts,
      relevantData?.elementsData?.elementCosts,
      relevantData?.prodHead.year,
      relevantData?.packagingData?.packagingStandardCosts,
      relevantData?.packagingData?.packagingNonstandardCosts,
      relevantData?.packagingData?.packingNonstandardCosts,
      relevantData?.packagingData?.packingStandardCosts,
      selectedOverheadRateEU,
      selectedOverheadRateUS,
      selectedScrapRateEU,
      selectedScrapRateUS,
      cc_Others,
      invalidRates,
    ],
  );

  const { cc_FinishedGoodOverhead } = useFinishedGoodsOverheadCostsOverviewRow(
    useFinishedGoodsOverheadCostsOverviewRowInput,
  );

  useEffect(() => {
    const elementsYear = relevantData?.latestApprovedRevisions.elements?.year;
    const packagingYear = relevantData?.latestApprovedRevisions.packaging?.year;
    const biYear = relevantData?.latestApprovedRevisions.buildingInstructions?.year;

    const messages: Message[] = [];

    if (elementsYear && elementsYear < relevantData.prodHead.year) {
      messages.push({
        id: 'outdated-revision_elements',
        variant: 'warning',
        message: 'Elements revision is outdated',
      });
    }
    if (biYear && biYear < relevantData.prodHead.year) {
      messages.push({
        id: 'outdated-revision_bi',
        variant: 'warning',
        message: 'Building instructions revision is outdated',
      });
    }
    if (packagingYear && packagingYear < relevantData.prodHead.year) {
      messages.push({
        id: 'outdated-revision_packaging',
        variant: 'warning',
        message: 'Packaging revision is outdated',
      });
    }

    clearAndAddMessages(/outdated-revision_.*/, messages);
  }, [
    clearAndAddMessages,
    relevantData?.latestApprovedRevisions.buildingInstructions?.year,
    relevantData?.latestApprovedRevisions.elements?.year,
    relevantData?.latestApprovedRevisions.packaging?.year,
    relevantData?.prodHead.year,
  ]);

  async function preSaveCheck(): Promise<FinalCostsSaveEndpointInput & { revision: number }> {
    if (!relevantData) {
      throw new Error('Application error. Data is missing');
    }

    return {
      productId: relevantData.prodHead.product_id,
      revision: relevantData.prodHead.revision,
      overheadTypeEU: selectedOverheadRateEU?.type,
      overheadTypeUS: selectedOverheadRateUS?.type,
      scrapTypeEU: selectedScrapRateEU?.type,
      scrapTypeUS: selectedScrapRateUS?.type,
      othersCost: cc_Others_costs,
      licenseCost: cc_License_costs,
    };
  }

  async function preApproveCheck() {
    checkApproveFinalCost({
      overheadTypeEU: selectedOverheadRateEU?.type,
      overheadTypeUS: selectedOverheadRateUS?.type,
      scrapTypeEU: selectedScrapRateEU?.type,
      scrapTypeUS: selectedScrapRateUS?.type,
      fmcYear: config.fmc_active_year,
      prodHeadElem: relevantData?.latestApprovedRevisions.elements,
      prodHeadPack: relevantData?.latestApprovedRevisions.packaging,
      prodHeadBi: relevantData?.latestApprovedRevisions.buildingInstructions,
    });
  }

  const productId = getProductIndex().productId;

  const readOnlyAccess = useMemo(() => accessIsReadOnly(groupNames, PK.ProdHeadBI), [groupNames]);

  return (
    <GeneralPage
      title="Final costs"
      loading={loading || loadingNew}
      messages={messages}
      onSave={{ preSaveCheck, save }}
      pk={PK.ProdHeadFC}
      prodHead={relevantData?.prodHead}
      description={relevantData?.description}
      maxRevision={relevantData?.maxRevision}
      preApproveCheck={preApproveCheck}
      refresh={refresh}
      refreshNew={refreshNew}
    >
      {relevantData && (
        <NCAccordion
          sections={[
            {
              title: 'Product header',
              content: (
                <FinalCostsProductHeader
                  prodHead={relevantData.prodHead}
                  maxRevision={relevantData.maxRevision}
                  latestApprovedRevisions={relevantData.latestApprovedRevisions}
                  onRevisionSelect={handleRevisionSelect}
                  refresh={refresh}
                />
              ),
            },
            {
              title: 'Product costs',
              content: (
                <FinalCostsOverview
                  readOnly={readOnly}
                  elements={{
                    data: relevantData.elementsData,
                    scrapRatePercentEU: selectedScrapRateEU?.scrap_rate_percent,
                    scrapRatePercentUS: selectedScrapRateUS?.scrap_rate_percent,
                  }}
                  cc_FinishedGoodOverhead={cc_FinishedGoodOverhead}
                  latestApprovedRevisions={relevantData.latestApprovedRevisions}
                  bi={relevantData.biData}
                  packaging={relevantData.packagingData}
                  otherProductCosts={relevantData.otherProductCosts}
                  productCosts={relevantData.productCosts}
                  cc_Others={cc_Others}
                  cc_License={cc_License}
                />
              ),
            },
            {
              title: 'Detailed costs',
              content: (
                <FinalCostsDetailedCosts
                  prodHead={relevantData.prodHead}
                  latestApprovedRevisions={relevantData.latestApprovedRevisions}
                  elementsData={relevantData.elementsData}
                  buildingInstructionsData={relevantData.biData}
                  packagingData={relevantData.packagingData}
                  scrapOhLicenseProps={{
                    readOnly,
                    prodHead: relevantData.prodHead,
                    selectedOverheadRateEU,
                    selectedOverheadRateUS,
                    selectedScrapRateEU,
                    selectedScrapRateUS,
                    updateOverheadRate,
                    updateLicenseCost,
                    updateScrapRate,
                    overheadOptions,
                    scrapOptions,
                    updateOthersCost,
                    othersCosts: cc_Others_costs,
                    licenseCosts: cc_License_costs,
                  }}
                />
              ),
            },
            {
              title: 'Attachments',
              content: productId && (
                <NCAttachments
                  view="finalcosts"
                  productId={productId}
                  readOnly={readOnly || readOnlyAccess}
                />
              ),
            },
          ]}
        />
      )}
    </GeneralPage>
  );
}

function save(verifiedPayload: FinalCostsSaveEndpointInput) {
  return callEndpoint({
    endpoint: FinalCostsSaveEndpoint,
    input: verifiedPayload,
    errorHandling: { disable: true },
  });
}
