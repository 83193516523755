import { DBPackingNonstandardRate } from '@core/schemas/db/schema.db.packaging';
import {
  Calculate_FetchQuery,
  CalculatorPackagingPackingNonstandardCost,
} from '../calculator.types';
import { C, CalculationError, Calculator, useCacheOrFetchQuery } from '../calculator';
import { PK } from '@core/types/types.pk';
import {
  ReportStatement,
  getCostCustomReportStatement,
  getCostReportStatement,
  getRateReportStatement,
} from '../calculator.util.report';
import { Region, RegionEnum } from '@core/schemas/schema.common';
import { PackSource } from '@core/types/types.packaging';
import { round2 } from '../calculator.util';

export async function calculate_PackingNonstandardCost(props: {
  input: {
    packingNonstandardCost: CalculatorPackagingPackingNonstandardCost;
    sourceEu: PackSource;
    sourceUs: PackSource;
    fmcYear: number;
  };
  cache?: {
    packingNonstandardRates?: DBPackingNonstandardRate[];
    cost?: { fmc1_cost?: number; fmc2_cost?: number };
  };
  query?: { query?: Calculate_FetchQuery };
}): Promise<{ fmc1_cost: number; fmc2_cost: number; report: ReportStatement[] }> {
  const { input, cache } = props;
  const { packingNonstandardCost, sourceEu, sourceUs, fmcYear } = input;

  const report: ReportStatement[] = [];

  if (cache?.cost) {
    if (cache.cost.fmc1_cost === undefined) {
      throw new CalculationError({
        region: 'EU',
        message: 'Cost was provided as cache but fmc1_cost was missing',
      });
    }

    if (cache.cost.fmc2_cost === undefined) {
      throw new CalculationError({
        region: 'US',
        message: 'Cost was provided as cache but fmc2_cost was missing',
      });
    }

    return {
      fmc1_cost: round2(cache.cost.fmc1_cost),
      fmc2_cost: round2(cache.cost.fmc2_cost),
      report: [
        getCostReportStatement({
          region: RegionEnum.Enum.EU,
          cache: true,
          cost: cache.cost.fmc1_cost,
        }),
        getCostReportStatement({
          region: RegionEnum.Enum.US,
          cache: true,
          cost: cache.cost.fmc2_cost,
        }),
      ],
    };
  }

  const defaultFmc1 =
    packingNonstandardCost.fmc1quantity === 0 &&
    packingNonstandardCost.fmc1machineTime === 0 &&
    packingNonstandardCost.fmc1operators === 0
      ? 0
      : undefined;
  const defaultFmc2 =
    packingNonstandardCost.fmc2quantity === 0 &&
    packingNonstandardCost.fmc2machineTime === 0 &&
    packingNonstandardCost.fmc2operators === 0
      ? 0
      : undefined;

  if (defaultFmc1 !== undefined && defaultFmc2 !== undefined) {
    return {
      fmc1_cost: round2(defaultFmc1),
      fmc2_cost: round2(defaultFmc1),
      report: [
        getRateReportStatement({ region: 'EU', rateType: 'default_from_quantity' }),
        getRateReportStatement({ region: 'US', rateType: 'default_from_quantity' }),
      ],
    };
  }

  const packingNonstandardRates = await useCacheOrFetchQuery({
    cachedItems: props.cache?.packingNonstandardRates,
    fetchQuery: props.query?.query,
    fetchQueryArgs: {
      pk: PK.PackingNonstandardRate,
      query: Calculator.Pack.Packing.NonstandardCost.Filter.PackingNonstandardRates({
        year: fmcYear,
      }),
    },
    dataTypeDescription: 'packing non-standard rate',
  });

  function calculateCostRegional(region: Region): { cost: number; report: ReportStatement[] } {
    const report: ReportStatement[] = [];

    const quantity =
      region === 'EU' ? packingNonstandardCost.fmc1quantity : packingNonstandardCost.fmc2quantity;

    if (!quantity) {
      return { cost: 0, report: [] };
    }

    const source = region === 'EU' ? sourceEu : sourceUs;

    const rate = packingNonstandardRates.find(
      (rate) =>
        rate.source === source &&
        rate.packing_type === packingNonstandardCost.packingType &&
        rate.packing_region === region,
    );

    if (rate === undefined) {
      throw new CalculationError({
        report: getRateReportStatement({
          region,
          rateType: 'Packing non-standard rate',
          rate: 'Not found',
          rateIdentifier: [
            {},
            [
              {
                year: fmcYear,
                source,
                packing_type: packingNonstandardCost.packingType,
                packing_region: region,
              },
            ],
          ],
        }),
      });
    }

    report.push(
      getRateReportStatement({
        region,
        rateType: 'Packing non-standard rate',
        rateTypeAdditionl: 'machine',
        rate: rate.machine_rate,
        rateIdentifier: [rate, ['year', 'source', 'packing_type', 'packing_region']],
      }),
    );
    report.push(
      getRateReportStatement({
        region,
        rateType: 'Packing non-standard rate',
        rateTypeAdditionl: 'operator',
        rate: rate.operator_rate,
        rateIdentifier: [rate, ['year', 'source', 'packing_type', 'packing_region']],
      }),
    );

    const machineTime =
      region === 'EU'
        ? packingNonstandardCost.fmc1machineTime
        : packingNonstandardCost.fmc2machineTime;

    const machineCosts = C.QuotientStrict(machineTime, 3600) * rate.machine_rate;
    report.push(
      getCostCustomReportStatement({
        region,
        cost: machineCosts,
        extraTag: 'machine',
        description: `(Machine time (${machineTime}) / 3600) · Machine rate (${rate.machine_rate})`,
      }),
    );

    const operators =
      region === 'EU' ? packingNonstandardCost.fmc1operators : packingNonstandardCost.fmc2operators;

    const operatorCosts = C.QuotientStrict(machineTime * operators, 3600) * rate.operator_rate;
    report.push(
      getCostCustomReportStatement({
        region,
        cost: operatorCosts,
        extraTag: 'operator',
        description: `((Machine time (${machineTime}) · Operators (${operators})) / 3600) · Operator rate (${rate.operator_rate})`,
      }),
    );

    const cost = quantity * (machineCosts + operatorCosts);
    report.push(
      getCostCustomReportStatement({
        region,
        cost: cost,
        description: `Quantity (${quantity}) · (Machine cost (${machineCosts.toFixed(
          2,
        )}) + Operator cost (${operatorCosts.toFixed(2)}))`,
      }),
    );

    return { cost, report };
  }

  const { cost: fmc1_cost, report: fmc1_report } = calculateCostRegional('EU');
  const { cost: fmc2_cost, report: fmc2_report } = calculateCostRegional('US');

  return {
    fmc1_cost: round2(fmc1_cost),
    fmc2_cost: round2(fmc2_cost),
    report: report.concat(...fmc1_report, ...fmc2_report),
  };
}
