import { AMLogActionType, DBAMLog } from '@core/schemas/db/schema.db.common';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { getFreshId } from '@core/util/util.geFreshId';
import { now } from '@core/util/util.timestamp';

export const AMLogExample: OmitDBRecord<DBAMLog>[] = [
  {
    id: getFreshId(),
    group_id: getFreshId(),
    action_by: 'abd@abc.com',
    action_on: 'efg@abc.com',
    action_type: AMLogActionType.Add,
    timestamp: now(),
  },
];
