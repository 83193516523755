import { useContext, useEffect, useMemo, useRef } from 'react';
import { callEndpoint } from '../callEndpoint';
import { LockRevisionEndpoint } from '@core/schemas/endpoint/schema.endpoint.common';
import { PK } from '@core/types/types.pk';
import { UserDataContext } from '../contexts';
import { accessIsReadOnly } from '../functions';

export function useTryLockRevision(input: {
  pk: PK.ProdHeadBI | PK.ProdHeadFC | PK.ProdHeadPack | PK.ProdHeadElem | undefined;
  productId: number | undefined;
  revision: number | undefined;
}) {
  const { groupNames } = useContext(UserDataContext);

  const intervalRef = useRef<NodeJS.Timer>();
  const hasRunFirstTime = useRef(false);

  async function tryLock(innerInput: {
    pk: PK.ProdHeadBI | PK.ProdHeadFC | PK.ProdHeadPack | PK.ProdHeadElem | undefined;
    productId: number | undefined;
    revision: number | undefined;
  }) {
    const { pk, productId, revision } = innerInput;

    if (!pk || pk === PK.ProdHeadElem || !productId || !revision) {
      return;
    }

    await callEndpoint({
      endpoint: LockRevisionEndpoint,
      input: { pk, productId, revision },
      errorHandling: { header: 'Locking revision' },
    });
  }

  const readOnlyAccess = useMemo(
    () => !input.pk || accessIsReadOnly(groupNames, input.pk),
    [groupNames, input.pk],
  );

  useEffect(() => {
    if (!input.pk || !input.productId || !input.revision || readOnlyAccess) {
      return;
    }

    if (!hasRunFirstTime.current) {
      hasRunFirstTime.current = true;
      tryLock(input);
    }

    intervalRef.current = setInterval(() => {
      tryLock(input);
    }, 20_000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [input, readOnlyAccess]);
}
