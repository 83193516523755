import { z } from 'zod';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import {
  ADGroupMemberSchema,
  ADGroupWithMembersSchema,
  UserWithGroupsSchema,
} from '@core/schemas/schema.common';
import { DBAMLogSchema } from '@core/schemas/db/schema.db.common';

// AMAddMembersToGroupsEndpoint
export const AMAddMembersToGroupsEndpoint = defineEndpoint({
  path: '/am/add-members-to-groups',
  mutation: true,
  input: z.object({
    emails: z.array(z.string()),
    groupIds: z.array(z.string()),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});

// AMRemoveMemberEndpoint
export const AMRemoveMembersEndpoint = defineEndpoint({
  path: '/am/remove-members',
  mutation: true,
  input: z.object({
    emails: z.array(z.string()),
    groupId: z.string(),
  }),
  response: z.object({
    ok: z.literal(true),
  }),
});

// AMGetGroupMembersEndpoint
export const AMGetGroupMembersEndpoint = defineEndpoint({
  path: '/am/get-group-members',
  input: z.object({
    groupId: z.string(),
  }),
  response: z.array(ADGroupMemberSchema),
});

// AMGetLogsForGroupEndpoint
export const AMGetLogsForGroupEndpoint = defineEndpoint({
  path: '/am/get-logs-for-group',
  input: z.object({
    groupId: z.string(),
  }),
  response: z.array(DBAMLogSchema),
});

export const EmailLOVEndpoint = defineEndpoint({
  path: '/am/email-lov',
  input: z.object({}),
  response: z.array(z.string()),
});

// AMGetGroupsAndUsersEndpoint
export type AMGetGroupsAndUsersEndpointResponse = z.infer<
  (typeof AMGetGroupsAndUsersEndpoint)['response']
>;
export const AMGetGroupsAndUsersEndpoint = defineEndpoint({
  path: '/am/get-groups-and-users',
  input: z.object({ noCache: z.boolean().optional() }),
  response: z.object({
    groups: z.array(ADGroupWithMembersSchema),
    users: z.array(UserWithGroupsSchema),
  }),
});
