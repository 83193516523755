// IMPORRTANT THAT THESE TWO ARE IDENTICAL, THE TYPE IS USED FOR EASE OF USE IN AUTHS FOR ENDPOINTS
export enum AMGroupNameEnum {
  Administrators = 'Administrators',
  ElementsUsers = 'ElementsUsers',
  PackagingUsers = 'PackagingUsers',
  BIUsers = 'BIUsers',
  FinalCostsUsers = 'FinalCostsUsers',
  TheCostingGroup = 'TheCostingGroup',
  DisplayAll = 'DisplayAll',
  Reporting = 'Reporting',
}

export type AMGroupName =
  | 'Administrators'
  | 'ElementsUsers'
  | 'PackagingUsers'
  | 'BIUsers'
  | 'FinalCostsUsers'
  | 'TheCostingGroup'
  | 'DisplayAll'
  | 'Reporting';
