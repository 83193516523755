import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { Calculator } from '../calculator';
import { CostComponentFull, CalculatorCommonPPO } from '../calculator.types';
import { CostComponent } from '@core/types/types.costComponent';
import { round2relaxed } from '../calculator.util';

export function calculate_Elements_Total(props: {
  elements: DBElemCost[];
  ppos: Record<
    'LEGOElements' | 'PurchasedElements' | 'OverdoseElements' | 'ElementsScrap',
    CalculatorCommonPPO
  >;
  scrapRatePercentEU: number | undefined;
  scrapRatePercentUS: number | undefined;
  copyPpoToCost?: boolean;
}): CostComponentFull {
  const { ppos, elements, scrapRatePercentEU, scrapRatePercentUS, copyPpoToCost } = props;

  const fmc1ppo = Calculator.Common.SumListPrimitive([
    ppos.LEGOElements?.FMC1,
    ppos.PurchasedElements?.FMC1,
    ppos.OverdoseElements?.FMC1,
    ppos.ElementsScrap?.FMC1,
  ]);
  const fmc2ppo = Calculator.Common.SumListPrimitive([
    ppos.LEGOElements?.FMC2,
    ppos.PurchasedElements?.FMC2,
    ppos.OverdoseElements?.FMC2,
    ppos.ElementsScrap?.FMC2,
  ]);

  if (copyPpoToCost) {
    return {
      fmc1cost: round2relaxed(fmc1ppo),
      fmc2cost: round2relaxed(fmc2ppo),
      fmc1ppo: round2relaxed(fmc1ppo),
      fmc2ppo: round2relaxed(fmc2ppo),
      fmc1variance: round2relaxed(fmc1ppo === undefined ? undefined : 0),
      fmc2variance: round2relaxed(fmc2ppo === undefined ? undefined : 0),
    };
  }

  const costCCLEGOElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.LEGOElements,
    { noRounding: true },
  );
  const costCCPurchasedElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.PurchasedElements,
    { noRounding: true },
  );
  const costCCOverdoseElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.OverdoseElements,
    { noRounding: true },
  );
  const { fmc1cost: fmc1costCCElementsScrap, fmc2cost: fmc2costCCElementsScrap } =
    Calculator.Elements.CC_ElementsScrap.Sum({
      elements,
      scrapRatePercentEU,
      scrapRatePercentUS,
      options: {
        noRounding: true,
      },
    });

  const fmc1cost = Calculator.Common.SumListPrimitive([
    costCCLEGOElements,
    costCCPurchasedElements,
    costCCOverdoseElements,
    fmc1costCCElementsScrap,
  ]);
  const fmc2cost = Calculator.Common.SumListPrimitive([
    costCCLEGOElements,
    costCCPurchasedElements,
    costCCOverdoseElements,
    fmc2costCCElementsScrap,
  ]);

  return {
    fmc1cost: round2relaxed(fmc1cost),
    fmc2cost: round2relaxed(fmc2cost),
    fmc1ppo: round2relaxed(fmc1ppo),
    fmc2ppo: round2relaxed(fmc2ppo),
    fmc1variance: round2relaxed(Calculator.Common.Variance(fmc1ppo, fmc1cost)),
    fmc2variance: round2relaxed(Calculator.Common.Variance(fmc2ppo, fmc2cost)),
  };
}
