import { z } from 'zod';
import { DBOHRateSchema, DBElementsScrapRateSchema } from '@core/schemas/db/schema.db.common';
import { defineEndpoint } from '@core/util/util.defineEndpoint';
import {
  DBProdCostSchema,
  DBProdHeadFCSchema,
  DBProdPPOSchema,
  DBProdParamsSchema,
} from '@core/schemas/db/schema.db.prod';
import { RegionFMCRecordSchema } from '@core/schemas/schema.common';
import { DBElemCostSchema, DBProdHeadElemSchema } from '@core/schemas/db/schema.db.elements';
import { DBBICostSchema, DBProdHeadBISchema } from '@core/schemas/db/schema.db.bi';
import {
  DBPackagingAddonRateSchema,
  DBPackagingFoilRateSchema,
  DBPackSourceSchema,
  DBPackagingRateSchema,
  DBPackagingScrapRateSchema,
  DBPackingStandardPrepackRateSchema,
  DBPackingStandardCostSchema,
  DBPackingStandardFinalPackRateSchema,
  DBPackingNonstandardCostSchema,
  DBPackingNonstandardRateSchema,
  DBProdHeadPackSchema,
  DBPackingNonstandardKeySchema,
  DBPackagingStandardCostSchema,
  DBPackagingNonstandardCostSchema,
} from '@core/schemas/db/schema.db.packaging';
import {
  OMIT_DB_RECORD,
  OMIT_DB_RECORD_EXCEPT_PK,
} from '@core/schemas/const/schema.const.OMIT_DB_RECORD';
import { CostComponent } from '@core/types/types.costComponent';
import { PackagingSizeSchema } from '../interface/schema.interface';

const FinalCostsGetResponseSchema = z.object({
  prodHead: DBProdHeadFCSchema.omit(OMIT_DB_RECORD_EXCEPT_PK),
  description: z.string(),
  maxRevision: z.number().int(),
  productCosts: z.object({
    elements: z.object({
      cost: z.object({
        [CostComponent.ElementsScrap]: DBProdCostSchema.optional(),
      }),
      ppo: z.object({
        [CostComponent.LEGOElements]: RegionFMCRecordSchema.partial(),
        [CostComponent.PurchasedElements]: RegionFMCRecordSchema.partial(),
        [CostComponent.OverdoseElements]: RegionFMCRecordSchema.partial(),
        [CostComponent.ElementsScrap]: RegionFMCRecordSchema.partial(),
      }),
    }),
    bi: z.object({
      ppo: RegionFMCRecordSchema.partial(),
    }),
    packaging: z.object({
      ppo: z.object({
        [CostComponent.Packing]: RegionFMCRecordSchema.partial(),
        [CostComponent.Packaging]: RegionFMCRecordSchema.partial(),
      }),
    }),
  }),
  latestApprovedRevisions: z.object({
    elements: DBProdHeadElemSchema.nullable(),
    buildingInstructions: DBProdHeadBISchema.nullable(),
    packaging: DBProdHeadPackSchema.nullable(),
  }),
  elementsData: z
    .object({
      elementCosts: z.array(
        DBElemCostSchema.merge(
          z.object({
            description: z.string().optional(),
          }),
        ),
      ),
    })
    .nullable(),
  biData: z
    .object({
      biCosts: z.array(DBBICostSchema.extend({ description: z.string() }).omit(OMIT_DB_RECORD)),
      biQrCode: z.boolean(),
    })
    .nullable(),
  packagingData: z
    .object({
      prodHead: DBProdHeadPackSchema,
      packingStandardFinalPackRates: z.array(DBPackingStandardFinalPackRateSchema),
      packingStandardPrepackRates: z.array(DBPackingStandardPrepackRateSchema),
      packingStandardCosts: z.array(DBPackingStandardCostSchema),
      packagingSourceOptions: z.array(DBPackSourceSchema),
      packingNonstandardCosts: z.array(DBPackingNonstandardCostSchema),
      packingNonstandardKeys: z.array(DBPackingNonstandardKeySchema),
      packingNonstandardRates: z.array(DBPackingNonstandardRateSchema),
      packagingAddOnRates: z.array(DBPackagingAddonRateSchema),
      packagingStandardCosts: z.array(DBPackagingStandardCostSchema),
      packagingNonstandardCosts: z.array(DBPackagingNonstandardCostSchema),
      packagingScrapRates: z.array(DBPackagingScrapRateSchema),
      packagingFoilRates: z.array(DBPackagingFoilRateSchema),
      packagingRates: z.array(DBPackagingRateSchema),
      ppoPackagingSize: PackagingSizeSchema.optional(),
    })
    .nullable(),
  scrapTypeEU: z.number().int().optional(),
  scrapTypeUS: z.number().int().optional(),
  overheadTypeEU: z.number().int().optional(),
  overheadTypeUS: z.number().int().optional(),
  otherProductCosts: z.object({
    ppo: z.object({
      [CostComponent.License]: DBProdPPOSchema.optional(),
      [CostComponent.FinishedGoodOverhead]: DBProdPPOSchema.optional(),
      [CostComponent.LeadPrice]: DBProdPPOSchema.optional(),
      [CostComponent.Factor]: DBProdPPOSchema.optional(),
      [CostComponent.Others]: DBProdPPOSchema.optional(),
      [CostComponent.TotalCosts]: DBProdPPOSchema.optional(),
    }),
    cost: z.object({
      [CostComponent.License]: DBProdCostSchema.optional(),
      [CostComponent.FinishedGoodOverhead]: DBProdCostSchema.optional(),
      [CostComponent.LeadPrice]: DBProdCostSchema.optional(),
      [CostComponent.Factor]: DBProdCostSchema.optional(),
      [CostComponent.Others]: DBProdCostSchema.optional(),
      [CostComponent.TotalCosts]: DBProdCostSchema.optional(),
    }),
  }),
});

// FinalCostsGetEndpoint
export type FinalCostsGetEndpointInput = z.infer<typeof FinalCostsGetEndpoint.input>;
export type FinalCostsGetEndpointResponse = z.infer<typeof FinalCostsGetEndpoint.response>;
export const FinalCostsGetEndpoint = defineEndpoint({
  path: '/final-costs/get',
  input: z.object({
    productId: z.number().int(),
    rev: z.number().int().optional(),
  }),
  response: FinalCostsGetResponseSchema,
});

// FinalCostsNewGetEndpoint
export const FinalCostsNewGetEndpoint = defineEndpoint({
  path: '/final-costs/get/new',
  input: z.object({
    productId: z.number().int(),
  }),
  response: FinalCostsGetResponseSchema,
});
export type FinalCostsNewGetEndpointInput = z.infer<typeof FinalCostsNewGetEndpoint.input>;
export type FinalCostsNewGetEndpointResponse = z.infer<typeof FinalCostsNewGetEndpoint.response>;

// FinalCostsSaveEndpoint
export const FinalCostsSaveEndpoint = defineEndpoint({
  path: '/final-costs/save',
  mutation: true,
  input: z.object({
    productId: z.number().int(),
    revision: z.union([z.number().int(), z.literal('NEW')]),
    scrapTypeEU: z.number().int().optional(),
    scrapTypeUS: z.number().int().optional(),
    overheadTypeEU: z.number().int().optional(),
    overheadTypeUS: z.number().int().optional(),
    othersCost: z.object({
      fmc1cost: z.number(),
      fmc2cost: z.number(),
    }),
    licenseCost: z.object({
      fmc1cost: z.number(),
      fmc2cost: z.number(),
    }),
  }),
  response: z.object({
    savedRevision: z.number().int(),
  }),
});
export type FinalCostsSaveEndpointInput = z.infer<typeof FinalCostsSaveEndpoint.input>;
export type FinalCostsSaveEndpointResponse = z.infer<typeof FinalCostsSaveEndpoint.response>;

// ElementsScrapRatesGetEndpoint
export const ElementsScrapRatesGetEndpoint = defineEndpoint({
  path: '/elements-scrap-rates/get',
  input: z.object({
    year: z.number().int(),
  }),
  response: z.array(DBElementsScrapRateSchema),
});
export type ElementsScrapRatesGetEndpointInput = z.infer<
  typeof ElementsScrapRatesGetEndpoint.input
>;
export type ElementsScrapRatesGetEndpointResponse = z.infer<
  typeof ElementsScrapRatesGetEndpoint.response
>;

// OverheadRatesGetEndpoint
export const OverheadRatesGetEndpoint = defineEndpoint({
  path: '/overhead-rates/get',
  input: z.object({
    year: z.number().int(),
  }),
  response: z.array(DBOHRateSchema),
});
export type OverheadRatesGetEndpointInput = z.infer<typeof OverheadRatesGetEndpoint.input>;
export type OverheadRatesGetEndpointResponse = z.infer<typeof OverheadRatesGetEndpoint.response>;
