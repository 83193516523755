import { Table } from '@frontend/table/table';
import { ADGroupColumns, getAllUsersColumns } from '../administration.columns.am';
import { useContext, useEffect, useMemo, useState } from 'react';
import { NCModal } from '@frontend/common/components/NCModal';
import { GroupModalContent } from './GroupModalContent';
import { Tabs } from '@lego/klik-ui';
import {
  AMGetGroupsAndUsersEndpoint,
  AMGetGroupsAndUsersEndpointResponse,
  EmailLOVEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.am';
import { UserDataContext } from '@frontend/common/lib/contexts';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { showErrorToast } from '@frontend/common/lib/functions';

export function AccessManagement() {
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const { data: emailLov } = useEndpoint({
    endpoint: EmailLOVEndpoint,
    input: {},
    errorHandling: { header: 'Fetching email suggestions' },
  });
  const [isFetching, setIsFetching] = useState(false);
  const [groupsAndUsers, setGroupsAndUsers] = useState<AMGetGroupsAndUsersEndpointResponse>();

  useEffect(() => {
    fetchGroupsAndUsers();
  }, []);

  const selectedGroup = useMemo(
    () => groupsAndUsers?.groups.find((g) => g.id === selectedGroupId),
    [groupsAndUsers, selectedGroupId],
  );

  async function fetchGroupsAndUsers(options?: { noCache: boolean }) {
    setIsFetching(true);
    const [err, result] = await callEndpoint({
      endpoint: AMGetGroupsAndUsersEndpoint,
      input: { noCache: options?.noCache },
    });
    setIsFetching(false);

    if (err) {
      showErrorToast('Fetching groups and users', err.error, { requestId: err.requestId });
      return;
    }

    setGroupsAndUsers(result);
  }

  const { getName } = useContext(UserDataContext);

  const allUsersColumns = useMemo(() => getAllUsersColumns(getName), [getName]);

  return (
    <div>
      {selectedGroup && (
        <NCModal
          header={selectedGroup.name}
          isOpen
          width={800}
          onClose={() => setSelectedGroupId('')}
        >
          <GroupModalContent
            emailLov={emailLov || []}
            group={selectedGroup}
            isRefreshing={isFetching}
            refresh={() => fetchGroupsAndUsers({ noCache: true })}
          />
        </NCModal>
      )}
      <Tabs>
        <Tabs.TabList>
          <Tabs.Tab className="tab-header">Groups</Tabs.Tab>
          <Tabs.Tab className="tab-header">Users</Tabs.Tab>
        </Tabs.TabList>
        <Tabs.TabPanels>
          <Tabs.TabPanel>
            <Table
              id="administration_groups"
              rows={groupsAndUsers?.groups || []}
              columns={ADGroupColumns}
              rowKey="id"
              itemType="group"
              isRefreshing={isFetching}
              removeInfoText
              removeSearch
              onRowClick={(r) => setSelectedGroupId(r.id)}
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <Table
              id="administration_users"
              rows={groupsAndUsers?.users || []}
              columns={allUsersColumns}
              isRefreshing={isFetching}
              rowKey="email"
              itemsPerPage={15}
              itemType="user"
            />
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs>
    </div>
  );
}
