import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ConfigContext,
  LoadingOverlayContext,
  ProductIndexContext,
  UserDataContext,
} from '@frontend/common/lib/contexts';
import { PackagingProductHeader } from '@frontend/packaging/components/PackagingProductHeader';
import { PackagingCostOverview } from '@frontend/packaging/components/PackagingCostOverview';
import { PackagingPackingCosts } from '@frontend/packaging/components/PackagingPackingCosts';
import { PackagingSourceAndRegions } from '@frontend/packaging/components/PackagingSourceAndRegions';
import { usePackingStandardCostsRows } from '@frontend/packaging/lib/usePackingStandardCostsRows';
import { GeneralPage } from '@frontend/common/components/GeneralPage';
import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { NCAttachments } from '@frontend/common/components/NCAttachments';
import { usePackingNonstandardCostsRows } from '../lib/usePackingNonstandardCostsRows';
import { PackagingPackagingCosts } from './PackagingPackagingCosts';
import { usePackagingAddOns } from '../lib/usePackagingAddOns';
import { usePackagingCostsRows } from '../lib/usePackagingCostsRows';
import { PK } from '@core/types/types.pk';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import {
  PackagingGetEndpoint,
  PackagingNewGetEndpoint,
  PackagingSaveEndpoint,
  PackagingSaveEndpointInput,
  PackagingSizesListEndpoint,
  PackagingSizesListEndpointResponse,
} from '@core/schemas/endpoint/schema.endpoint.packaging';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import { Unpacked } from '@core/util/util.typing';
import { Region, RegionEnum } from '@core/schemas/schema.common';
import {
  accessIsReadOnly,
  closeToast,
  isRevisionReadOnly,
  showErrorToast,
  showInfoToast,
} from '@frontend/common/lib/functions';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { PackSource } from '@core/types/types.packaging';
import { checkApprovePack } from '@core/checks/check.approve.pack';

export function PackagingPage() {
  const [note, setNote] = useState('');

  const { getProductIndex, setRevision, setSuggestedProductId, clearProductIndex } =
    useContext(ProductIndexContext);
  const rev = getProductIndex().revision;

  const {
    data,
    error,
    loading,
    statusCode,
    queryFunction: refresh,
  } = useEndpoint({
    endpoint: PackagingGetEndpoint,
    input: {
      productId: convertProductIdTo8digits(getProductIndex().productId || 0),
      rev: rev === 'new' ? 1 : rev,
    },
    condition: !!getProductIndex().productId && typeof rev === 'number',
    errorHandling: { disable: true },
  });

  const {
    data: dataNew,
    loading: loadingNew,
    queryFunction: refreshNew,
  } = useEndpoint({
    endpoint: PackagingNewGetEndpoint,
    input: { productId: convertProductIdTo8digits(getProductIndex().productId || 0) },
    condition: !!getProductIndex().productId && rev === 'new',
    errorHandling: {
      header: 'Fetching initial packaging data',
      handlers: {
        '400': (message) => {
          const revisionExists = message === 'A revision already exists for this product';
          if (revisionExists) {
            setRevision(undefined);
          }
          (revisionExists ? showInfoToast : showErrorToast)(
            'Fetching initial packaging data',
            message,
          );
        },
      },
    },
  });

  const relevantData = useMemo(() => data || dataNew, [data, dataNew]);

  const { data: packagingSizes, loading: packagingSizesLoading } = useEndpoint({
    endpoint: PackagingSizesListEndpoint,
    input: {},
    errorHandling: { header: 'Fetching packaging sizes' },
    condition: !!relevantData,
  });
  const [boxFillingDegree, setBoxFillingDegree] = useState(0);
  const [selectedPackaging, setSelectedPackaging] =
    useState<Unpacked<PackagingSizesListEndpointResponse>>();
  const [modelBagChecked, setModelBagChecked] = useState(false);
  const [selectedSourceEU, setSelectedSourceEU] = useState<PackSource>();
  const [selectedSourceUS, setSelectedSourceUS] = useState<PackSource>();
  const [packingRegionEU, setPackingRegionEU] = useState<Region>();
  const [packingRegionUS, setPackingRegionUS] = useState<Region>();
  const [salesRegionEU, setSalesRegionEU] = useState<Region>('EU');
  const [salesRegionUS, setSalesRegionUS] = useState<Region>('US');

  const { config } = useContext(ConfigContext);
  const { email, domainUsername, groupNames } = useContext(UserDataContext);

  useEffect(() => {
    if (error && getProductIndex().productId && getProductIndex().revision !== 'new') {
      if (statusCode === 404) {
        setSuggestedProductId(getProductIndex().productId);
        showInfoToast(
          'Revision does not exist',
          getProductIndex().revision
            ? `Product does not have a revision ${getProductIndex().revision}`
            : 'Product does not have a packaging revision',
        );
        clearProductIndex();
      } else {
        showErrorToast('Fetching packaging data', error.error, {
          id: 'fetch-failed',
          requestId: error.requestId,
        });
      }
    } else {
      closeToast('fetch-failed');
    }
  }, [error, statusCode, clearProductIndex, setSuggestedProductId, getProductIndex]);

  const readOnly = useMemo(
    () =>
      isRevisionReadOnly({ prodHead: relevantData?.prodHead, email, year: config.fmc_active_year }),
    [email, config.fmc_active_year, relevantData?.prodHead],
  );

  const {
    rows: packingNonstandardCostsRows,
    handlers: packingNonstandardCostsHandlers,
    totals: packingNonstandardCostsTotals,
    messages: localPackingNonstandardCostsMessages,
  } = usePackingNonstandardCostsRows({
    fmcYear: relevantData?.prodHead.year,
    payloadPackingNonstandardCosts: relevantData?.packingNonstandardCosts,
    packingNonstandardRates: relevantData?.packingNonstandardRates,
    selectedSourceEU,
    selectedSourceUS,
    readOnly,
  });

  const {
    packingStandardCostsRows,
    handlers: packingStandardCostsHandlers,
    totals: packingStandardCostsTotals,
    messages: localPackingStandardCostsMessages,
  } = usePackingStandardCostsRows({
    year: relevantData?.prodHead.year,
    packingStandardPrepackRates: relevantData?.packingStandardPrepackRates,
    packingStandardCosts: relevantData?.packingStandardCosts,
    selectedPackaging: selectedPackaging,
    selectedSourceEU,
    selectedSourceUS,
    packingStandardFinalPackRates: relevantData?.packingStandardFinalPackRates,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
    clearPackingNonstandardFinalCosts:
      packingNonstandardCostsHandlers.clearPackingNonstandardFinalCosts,
    readOnly,
  });

  const { addOns, updateAddOn } = usePackagingAddOns(relevantData?.prodHead);

  const {
    isStandard,
    packagingNonstandardCostsRows,
    packagingStandardCostsRows,
    materials: packagingNonstandardMaterials,
    messages: localPackagingCostsMessages,
    nonstandardHandlers: packagingNonstandardCostsHandlers,
    nonstandardTotals: packagingNonstandardCostsTotals,
    reinitialize: reinitializePackagingCostRows,
    reset,
    standardHandlers: packagingStandardCostsHandlers,
    standardTotals: packagingStandardCostsTotals,
  } = usePackagingCostsRows({
    fmcYear: relevantData?.prodHead.year,
    modelBagChecked,
    packagingData: relevantData ?? undefined,
    selectedPackagingSize: selectedPackaging?.packaging_size,
    packingRegionEU,
    packingRegionUS,
    packingStandardCostsRows,
    salesRegionEU,
    salesRegionUS,
    selectedAddOns: addOns,
    selectedSourceEU,
    selectedSourceUS,
    productId: relevantData?.prodHead.product_id,
    revision: relevantData?.prodHead.revision,
    readOnly,
  });

  const { setIsLoading } = useContext(LoadingOverlayContext);

  useEffect(() => {
    setIsLoading(loading || loadingNew);
    return () => setIsLoading(false);
  }, [loading, loadingNew, setIsLoading]);

  useEffect(() => {
    if (!relevantData) {
      return;
    }

    reset();
    setModelBagChecked(!!relevantData.prodHead.model_bag);
    setBoxFillingDegree(relevantData.prodHead.box_filling_degree_pct);
    setSelectedSourceEU(relevantData.prodHead.source_eu);
    setSelectedSourceUS(relevantData.prodHead.source_us);
    setPackingRegionEU(relevantData.prodHead.packing_region_eu);
    setPackingRegionUS(relevantData.prodHead.packing_region_us);
    setSalesRegionEU(relevantData.prodHead.sales_region_eu || RegionEnum.Enum.EU);
    setSalesRegionUS(relevantData.prodHead.sales_region_us || RegionEnum.Enum.US);
    setNote(relevantData.prodHead.note);
  }, [relevantData, reset]);

  useEffect(() => {
    if (!relevantData) {
      return;
    }

    setSelectedPackaging(
      (packagingSizes || []).find(
        (ps) => ps.packaging_size === relevantData.prodHead.packaging_size,
      ),
    );
  }, [relevantData, packagingSizes]);

  function handleRevisionSelect(revision: number) {
    if (!getProductIndex().productId) return;

    setRevision(revision);
  }

  async function preSaveCheck(): Promise<PackagingSaveEndpointInput & { revision: number }> {
    if (!relevantData) {
      throw new Error('Application error. Data is missing');
    }

    return {
      domainUsername,
      productId: relevantData.prodHead.product_id,
      revision: relevantData.prodHead.revision,
      note,
      boxFillingDegree,
      alternativeCode: relevantData.prodHead.alternative_code,
      estimationCode: relevantData.prodHead.estimation_code,
      packagingSize: selectedPackaging?.packaging_size,
      modelBag: modelBagChecked,
      packSourceEU: !selectedSourceEU ? undefined : selectedSourceEU,
      packSourceUS: !selectedSourceUS ? undefined : selectedSourceUS,
      packingRegionEU,
      packingRegionUS,
      salesRegionEU,
      salesRegionUS,
      packingStandardCostsInput: packingStandardCostsRows,
      packingNonstandardCostsInput: packingNonstandardCostsRows,
      packagingAddOns: addOns,
      packagingStandardCostsInput: packagingStandardCostsRows,
      packagingNonstandardCostsInput: packagingNonstandardCostsRows,
    };
  }

  const productId = getProductIndex().productId;

  async function preApproveCheck() {
    checkApprovePack({
      packagingStandardCosts: packagingStandardCostsRows.map((r) => ({
        fmc1_cost: r.fmc1cost,
        fmc2_cost: r.fmc2cost,
      })),
      packagingNonstandardCosts: packagingNonstandardCostsRows.map((r) => ({
        fmc1_cost_plus_scrap: r.fmc1costPlusScrap,
        fmc2_cost_plus_scrap: r.fmc2costPlusScrap,
      })),
    });
  }

  const readOnlyAccess = useMemo(() => accessIsReadOnly(groupNames, PK.ProdHeadBI), [groupNames]);

  return (
    <GeneralPage
      title="Packaging"
      loading={loading || loadingNew}
      prodHead={relevantData?.prodHead}
      description={relevantData?.description}
      maxRevision={relevantData?.maxRevision}
      preApproveCheck={preApproveCheck}
      onSave={{
        preSaveCheck,
        save,
      }}
      refresh={refresh}
      refreshNew={refreshNew}
      pk={PK.ProdHeadPack}
    >
      {relevantData && (
        <NCAccordion
          sections={[
            {
              title: 'Product header',
              content: (
                <PackagingProductHeader
                  prodHead={relevantData.prodHead}
                  maxRevision={relevantData.maxRevision}
                  onRevisionSelect={handleRevisionSelect}
                />
              ),
              noBottomMargin: true,
            },
            {
              title: 'Product costs',
              content: (
                <PackagingCostOverview
                  productCosts={relevantData?.productCosts}
                  packingStandardCosts={packingStandardCostsRows}
                  packingNonstandardCosts={packingNonstandardCostsRows}
                  packagingStandardCost={packagingStandardCostsRows}
                  packagingNonstandardCost={packagingNonstandardCostsRows}
                />
              ),
            },
            {
              title: 'Sources and regions',
              content: (
                <PackagingSourceAndRegions
                  readOnly={readOnly}
                  packingRegionEU={packingRegionEU}
                  packingRegionUS={packingRegionUS}
                  salesRegionEU={salesRegionEU}
                  salesRegionUS={salesRegionUS}
                  setPackingRegionEU={(reg) => {
                    setPackingRegionEU(reg || undefined);
                    reinitializePackagingCostRows({
                      fmcRegion: 'FMC1',
                      packingRegion: reg || undefined,
                    });
                  }}
                  setPackingRegionUS={(reg) => {
                    setPackingRegionUS(reg || undefined);
                    reinitializePackagingCostRows({
                      fmcRegion: 'FMC2',
                      packingRegion: reg || undefined,
                    });
                  }}
                  selectedSourceEU={selectedSourceEU}
                  selectedSourceUS={selectedSourceUS}
                  onSelectedSourceChange={(fmcRegion, newSource) => {
                    if (fmcRegion === 'FMC1') {
                      setSelectedSourceEU(newSource);
                    } else if (fmcRegion === 'FMC2') {
                      setSelectedSourceUS(newSource);
                    }

                    const sourceEU = fmcRegion === 'FMC1' ? newSource : selectedSourceEU;
                    const sourceUS = fmcRegion === 'FMC2' ? newSource : selectedSourceUS;

                    const packingRegion = fmcRegion === 'FMC1' ? packingRegionEU : packingRegionUS;
                    const salesRegion = fmcRegion === 'FMC1' ? salesRegionEU : salesRegionUS;

                    if (!packingRegion || !salesRegion || !sourceEU || !sourceUS) {
                      return;
                    }

                    reinitializePackagingCostRows({ fmcRegion, source: newSource });

                    if (selectedPackaging) {
                      packingStandardCostsHandlers.resetQuantities({
                        fmcRegion,
                        source: newSource,
                        packaging: selectedPackaging,
                        packingRegion,
                        salesRegion,
                      });
                    }
                  }}
                  sourceOptions={relevantData?.packagingSourceOptions || []}
                />
              ),
            },
            {
              title: 'Packing costs',
              content: (
                <PackagingPackingCosts
                  maxRevision={relevantData.maxRevision}
                  approved={!!relevantData.prodHead.approved_by}
                  ppoPackagingSize={relevantData.ppoPackagingSize}
                  hideCostTable={
                    !selectedPackaging ||
                    !packingRegionEU ||
                    !packingRegionUS ||
                    !selectedSourceEU ||
                    !selectedSourceUS
                  }
                  packingNonstandardKeys={relevantData.packingNonstandardKeys}
                  readOnly={readOnly}
                  modelBagChecked={modelBagChecked}
                  onModelBagChecked={setModelBagChecked}
                  onSelectedPackagingChange={(size) => {
                    setSelectedPackaging(size);
                    reinitializePackagingCostRows({
                      fmcRegion: 'FMC1',
                      packagingSize: size.packaging_size,
                    });
                    reinitializePackagingCostRows({
                      fmcRegion: 'FMC2',
                      packagingSize: size.packaging_size,
                    });

                    if (packingRegionEU) {
                      packingStandardCostsHandlers.resetQuantities({
                        fmcRegion: 'FMC1',
                        source: selectedSourceEU,
                        packaging: size,
                        packingRegion: packingRegionEU,
                        salesRegion: salesRegionEU,
                      });
                    }

                    if (packingRegionUS) {
                      packingStandardCostsHandlers.resetQuantities({
                        fmcRegion: 'FMC2',
                        source: selectedSourceUS,
                        packaging: size,
                        packingRegion: packingRegionUS,
                        salesRegion: salesRegionUS,
                      });
                    }
                  }}
                  note={note}
                  onNoteChange={setNote}
                  prodHead={relevantData.prodHead}
                  packagingSizesLoading={packagingSizesLoading}
                  selectedPackaging={selectedPackaging}
                  packagingSizes={packagingSizes || []}
                  boxFillingDegree={boxFillingDegree}
                  onBoxFillingDegreeChange={setBoxFillingDegree}
                  packingStandardCostsRows={packingStandardCostsRows}
                  packingStandardCostsTotals={packingStandardCostsTotals}
                  packingStandardCostsHandlers={packingStandardCostsHandlers}
                  packingStandardCostsMessages={localPackingStandardCostsMessages}
                  packingNonstandardCostsRows={packingNonstandardCostsRows}
                  packingNonstandardCostsTotals={packingNonstandardCostsTotals}
                  packingNonstandardCostsHandlers={packingNonstandardCostsHandlers}
                  packingNonstandardCostsMessages={localPackingNonstandardCostsMessages}
                />
              ),
            },
            {
              title: 'Packaging costs',
              content: (
                <PackagingPackagingCosts
                  maxRevision={relevantData.maxRevision}
                  productId={relevantData.prodHead.product_id}
                  revision={relevantData.prodHead.revision}
                  hideCostTable={
                    !selectedPackaging ||
                    !packingRegionEU ||
                    !packingRegionUS ||
                    !selectedSourceEU ||
                    !selectedSourceUS
                  }
                  readOnly={readOnly}
                  addOns={addOns}
                  updateAddOn={updateAddOn}
                  selectedPackagingSize={selectedPackaging}
                  packagingNonstandardCostsMaterials={packagingNonstandardMaterials}
                  packagingNonstandardCostsRows={packagingNonstandardCostsRows}
                  packagingNonstandardCostsTotal={packagingNonstandardCostsTotals}
                  packagingStandardCostsRows={packagingStandardCostsRows}
                  packagingStandardCostsTotal={packagingStandardCostsTotals}
                  packagingNonstandardCostsHandlers={packagingNonstandardCostsHandlers}
                  packagingStandardCostsHandlers={packagingStandardCostsHandlers}
                  isStandard={isStandard}
                  packagingCostsMessages={localPackagingCostsMessages}
                />
              ),
            },
            {
              title: 'Attachments',
              content: productId && (
                <NCAttachments
                  view="packaging"
                  productId={productId}
                  readOnly={readOnly || readOnlyAccess}
                />
              ),
            },
          ]}
        />
      )}
    </GeneralPage>
  );
}

function save(verifiedPayload: PackagingSaveEndpointInput) {
  return callEndpoint({
    endpoint: PackagingSaveEndpoint,
    input: verifiedPayload,
    errorHandling: { disable: true },
  });
}
