import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getErrorStatement, showErrorToast, showInfoToast } from '@frontend/common/lib/functions';
import { PackagingStandardCostsRow } from './packaging.types';
import { Calculator } from '@core/calculator/calculator';
import { PackagingGetEndpointResponse } from '@core/schemas/endpoint/schema.endpoint.packaging';
import { Region, RegionFMC } from '@core/schemas/schema.common';
import { Unpacked } from '@core/util/util.typing';
import { getErrorMessage } from '@core/util/util.getErrorMessage';
import { UsePackingStandardCostsRowsReturnValue } from './usePackingStandardCostsRows';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { Message } from '@frontend/common/lib/models';
import { PasteChanges } from '@frontend/table/lib';
import { DBPackagingRate, DBPackagingStandardCost } from '@core/schemas/db/schema.db.packaging';
import { PackSource, PackagingPackingKey } from '@core/types/types.packaging';
import { PACKAGING_KEY_TO_DESCRIPTION } from '@core/const/const.PACKAGING_KEY_TO_DESCRIPTION';
import { getFreshId } from '@core/util/util.geFreshId';

type FMCNumbers = {
  fmc1: number;
  fmc2: number;
};

export type UsePackagingStandardCostsReturnValue = {
  packagingStandardCostsRows: PackagingStandardCostsRow[];
  reset: () => void;
  reinitialize: (input: {
    fmcRegion: RegionFMC;
    filteredPackagingRates: DBPackagingRate[];
    source: PackSource;
    packingRegion: Region;
  }) => void;
  handlers: {
    changeDescription: (key: string, newDescription: string) => void;
    changeQuantity: (key: string, fmcRegion: RegionFMC, newQuantity: number) => void;
    changeCost: (key: string, fmcRegion: RegionFMC, newCost: number) => void;
    addRow: () => void;
    deleteRow: (key: string) => void;
    onPaste: (updates: PasteChanges<PackagingStandardCostsRow>) => void;
    importRows: (rows: DBPackagingStandardCost[]) => void;
  };
  totals: {
    cost: FMCNumbers;
    quantity: FMCNumbers;
  };
  messages: Message[];
};

export function usePackagingStandardCosts(props: {
  packagingData:
    | Pick<
        PackagingGetEndpointResponse,
        | 'packagingAddOnRates'
        | 'packagingScrapRates'
        | 'packagingFoilRates'
        | 'packagingStandardCosts'
        | 'packagingRates'
      >
    | undefined;
  packingStandardCostsRows: UsePackingStandardCostsRowsReturnValue['packingStandardCostsRows'];
  packingRegionEU: Region | undefined;
  packingRegionUS: Region | undefined;
  salesRegionEU: Region;
  salesRegionUS: Region;
  selectedSourceEU: PackSource | undefined;
  selectedSourceUS: PackSource | undefined;
  selectedPackagingSize: number | undefined;
  selectedAddOns: {
    embossing: boolean;
    uvLacquer: boolean;
    windowCutout: boolean;
    hotfoil: boolean;
    partialUvLacquer: boolean;
    windowFoil: boolean;
  };
  modelBagChecked: boolean;
  productId: number | undefined;
  revision: number | undefined;
  fmcYear: number | undefined;
  readOnly?: boolean;
}): UsePackagingStandardCostsReturnValue {
  const {
    fmcYear,
    modelBagChecked,
    packagingData,
    packingRegionEU,
    packingRegionUS,
    selectedPackagingSize,
    salesRegionEU,
    salesRegionUS,
    selectedAddOns,
    selectedSourceEU,
    selectedSourceUS,
    packingStandardCostsRows,
    readOnly,
    productId,
    revision,
  } = props;

  const [quantities, setQuantities] = useState<Record<string, Record<RegionFMC, number>>>({});
  const [descriptions, setDescriptions] = useState<Record<string, string>>({});
  const [manualCosts, setManualCosts] = useState<Record<string, Record<RegionFMC, number>>>({});
  const [keys, setKeys] = useState<{ key: string; custom: boolean }[]>([]);
  const [
    packagingStandardCostsRowsWithoutDescription,
    setPackagingStandardCostsRowsWithoutDescription,
  ] = useState<Omit<PackagingStandardCostsRow, 'description'>[]>([]);

  const {
    addMessage: addLocalMessage,
    clearMessages: clearLocalMessages,
    messages: localMessages,
  } = useMessages();

  const {
    packagingScrapRates,
    packagingAddOnRates,
    packagingFoilRates,
    packagingStandardCosts,
    packagingRates,
  } = packagingData || {};

  const updateRowsFromCostInputs = useCallback(
    (
      fmcRegion: RegionFMC,
      costInputs: {
        quantity?: number;
        packingKey: string;
        description: string;
        custom?: boolean;
        cost?: number;
      }[],
    ) => {
      const preQuantities: Record<string, number> = {};
      const preDescriptions: typeof descriptions = {};
      const preManualCosts: Record<string, number> = {};

      costInputs.forEach((r) => {
        preQuantities[r.packingKey] = r.quantity || 0;

        preDescriptions[r.packingKey] =
          r.description ||
          PACKAGING_KEY_TO_DESCRIPTION[r.packingKey as PackagingPackingKey] ||
          r.packingKey;

        if (r.custom) {
          preManualCosts[r.packingKey] = r.cost || 0;
        }
      });

      setQuantities((curr) => {
        const newQuantities = structuredClone(curr);
        Object.entries(preQuantities).forEach(([k, v]) => {
          if (!newQuantities[k]) {
            newQuantities[k] = { FMC1: 0, FMC2: 0 };
          }
          newQuantities[k][fmcRegion] = v;
        });
        return newQuantities;
      });
      setDescriptions(preDescriptions);
      setManualCosts((curr) => {
        const newManualCosts = structuredClone(curr);
        Object.entries(preManualCosts).forEach(([k, v]) => {
          if (!newManualCosts[k]) {
            newManualCosts[k] = { FMC1: 0, FMC2: 0 };
          }
          newManualCosts[k][fmcRegion] = v;
        });
        return newManualCosts;
      });
      setKeys(
        costInputs.map((cost) => ({
          key: cost.packingKey,
          custom: !!cost.custom,
        })),
      );
    },
    [],
  );

  // This is region based, but will use a calculator function that works on both region at the same time
  // so we only pick out the relevant quantity at the end
  const reinitialize = useCallback(
    async (input: {
      fmcRegion: RegionFMC;
      filteredPackagingRates: DBPackagingRate[];
      source: PackSource;
      packingRegion: Region;
    }) => {
      hasRunInitialSetup.current = true;

      // We take a shortcut and use first rate to grab year and pack size
      const firstRate = input.filteredPackagingRates.filter((r) => r.source === input.source)[0];

      const sourceEu = input.fmcRegion === 'FMC1' ? input.source : selectedSourceEU;
      const sourceUs = input.fmcRegion === 'FMC2' ? input.source : selectedSourceUS;
      const packingRegionEu = input.fmcRegion === 'FMC1' ? input.packingRegion : packingRegionEU;
      const packingRegionUs = input.fmcRegion === 'FMC2' ? input.packingRegion : packingRegionUS;

      if (!firstRate || !sourceEu || !sourceUs || !packingRegionEu || !packingRegionUs) {
        updateRowsFromCostInputs(input.fmcRegion, []);
        return;
      }

      const inputs = await Calculator.Pack.Packaging.StandardCost.GetRows.Run({
        input: {
          year: firstRate.year,
          sourceEu,
          sourceUs,
          packagingSize: firstRate.packaging_size,
          packingRegionEu,
          packingRegionUs,
          salesRegionEu: salesRegionEU,
          salesRegionUs: salesRegionUS,
        },
        cache: { packagingRates: input.filteredPackagingRates },
      });

      const newCostInputs = inputs.map((i) => ({
        packingKey: i.key,
        description: PACKAGING_KEY_TO_DESCRIPTION[i.key],
        quantity: input.fmcRegion === 'FMC1' ? i.fmc1quantity : i.fmc2quantity,
      }));

      updateRowsFromCostInputs(input.fmcRegion, newCostInputs);
    },
    [
      updateRowsFromCostInputs,
      salesRegionEU,
      salesRegionUS,
      selectedSourceEU,
      selectedSourceUS,
      packingRegionEU,
      packingRegionUS,
    ],
  );

  // first time hook
  const hasRunInitialSetup = useRef(false);
  useEffect(() => {
    if (hasRunInitialSetup.current) {
      return;
    }

    if (
      !packagingStandardCosts ||
      !selectedPackagingSize ||
      !selectedSourceEU ||
      !selectedSourceUS
    ) {
      return;
    }

    if (packagingStandardCosts.length === 0) {
      hasRunInitialSetup.current = true;
    } else {
      updateRowsFromCostInputs(
        'FMC1',
        packagingStandardCosts.map((psc) => ({
          description: psc.description,
          packingKey: psc.packing_key,
          cost: psc.fmc1_cost,
          custom: psc.custom,
          quantity: psc.fmc1_quantity,
        })),
      );
      updateRowsFromCostInputs(
        'FMC2',
        packagingStandardCosts.map((psc) => ({
          description: psc.description,
          packingKey: psc.packing_key,
          cost: psc.fmc2_cost,
          custom: psc.custom,
          quantity: psc.fmc2_quantity,
        })),
      );
      hasRunInitialSetup.current = true;
    }
  }, [
    updateRowsFromCostInputs,
    reinitialize,
    packagingStandardCosts,
    selectedPackagingSize,
    selectedSourceEU,
    selectedSourceUS,
  ]);

  useEffect(() => {
    if (
      !fmcYear ||
      !selectedSourceEU ||
      !selectedSourceUS ||
      !packingRegionEU ||
      !packingRegionUS ||
      !salesRegionEU ||
      !salesRegionUS ||
      !selectedPackagingSize ||
      !productId ||
      !revision
    ) {
      setPackagingStandardCostsRowsWithoutDescription([]);
      return;
    }

    (async () => {
      try {
        const packagingCostInputs = keys.map(({ key, custom }) => ({
          custom,
          fmc1quantity: quantities[key]?.FMC1,
          fmc2quantity: quantities[key]?.FMC2,
          fmc1manualCost: manualCosts[key]?.FMC1,
          fmc2manualCost: manualCosts[key]?.FMC2,
          key,
        }));

        const prePackagingStandardCostRows: typeof packagingStandardCostsRowsWithoutDescription =
          await Promise.all(
            packagingCostInputs.map(async (pci) => {
              const existingPackagingCost = packagingStandardCosts?.find(
                (pc) => pc.packing_key === pci.key,
              );
              try {
                const { fmc1_cost, fmc2_cost, report } =
                  await Calculator.Pack.Packaging.StandardCost.Calculate({
                    input: {
                      productId,
                      revision,
                      addOns: selectedAddOns,
                      modelBag: modelBagChecked,
                      packagingStandardCost: pci,
                      packingRegionEU,
                      packingRegionUS,
                      packagingSize: selectedPackagingSize,
                      salesRegionEU,
                      salesRegionUS,
                      sourceEU: selectedSourceEU,
                      sourceUS: selectedSourceUS,
                      fmcYear: fmcYear,
                    },
                    cache: {
                      packagingAddOnRates,
                      packagingFoilRates,
                      packingStandardCosts: packingStandardCostsRows.map((frr) => ({
                        fmc1_quantity: frr.fmc1quantity,
                        fmc2_quantity: frr.fmc2quantity,
                        packing_key: frr.packingKey,
                      })),
                      packagingRates,
                      cost: {
                        use: readOnly,
                        values: {
                          fmc1cost: existingPackagingCost?.fmc1_cost,
                          fmc2cost: existingPackagingCost?.fmc2_cost,
                        },
                      },
                    },
                  });

                const row: Unpacked<typeof packagingStandardCostsRowsWithoutDescription> = {
                  key: pci.key,
                  fmc1quantity: quantities[pci.key]?.FMC1,
                  fmc2quantity: quantities[pci.key]?.FMC2,
                  fmc1cost: fmc1_cost,
                  fmc2cost: fmc2_cost,
                  currency: 'DKK',
                  custom: pci.custom,
                  fmc1manualCost: pci.fmc1manualCost,
                  fmc2manualCost: pci.fmc2manualCost,
                  report: pci.custom ? report.filter((r) => r.type === 'Cost') : report,
                };

                return row;
              } catch (error) {
                const errorStatement = getErrorStatement(error);

                const row: Unpacked<typeof packagingStandardCostsRowsWithoutDescription> = {
                  key: pci.key,
                  fmc1quantity: quantities[pci.key]?.FMC1,
                  fmc2quantity: quantities[pci.key]?.FMC2,
                  fmc1cost: 0,
                  fmc2cost: 0,
                  currency: 'DKK',
                  custom: pci.custom,
                  fmc1manualCost: pci.fmc1manualCost,
                  fmc2manualCost: pci.fmc2manualCost,
                  report: errorStatement ? [errorStatement] : [],
                };

                return row;
              }
            }),
          );

        prePackagingStandardCostRows.sort((a, b) => {
          if (a.custom && b.custom) {
            return 0;
          }

          return a.custom ? 1 : -1 - (b.custom ? 1 : -1);
        });

        setPackagingStandardCostsRowsWithoutDescription(prePackagingStandardCostRows);
      } catch (error) {
        showErrorToast('Setting up packaging costs', getErrorMessage(error));
      }
    })();
  }, [
    revision,
    productId,
    keys,
    quantities,
    manualCosts,
    packagingStandardCosts,
    packagingAddOnRates,
    fmcYear,
    packagingFoilRates,
    packagingRates,
    modelBagChecked,
    packingStandardCostsRows,
    packingRegionEU,
    packingRegionUS,
    salesRegionEU,
    salesRegionUS,
    readOnly,
    selectedPackagingSize,
    packagingScrapRates,
    selectedAddOns,
    selectedSourceEU,
    selectedSourceUS,
  ]);

  const packagingStandardCostsRows = useMemo(
    () =>
      packagingStandardCostsRowsWithoutDescription.map((r) => ({
        ...r,
        description: descriptions[r.key] || '',
      })),
    [packagingStandardCostsRowsWithoutDescription, descriptions],
  );

  const totalCost = useMemo(
    () => ({
      fmc1: Calculator.Common.SumListStrict(
        packagingStandardCostsRowsWithoutDescription,
        'fmc1cost',
      ),
      fmc2: Calculator.Common.SumListStrict(
        packagingStandardCostsRowsWithoutDescription,
        'fmc2cost',
      ),
    }),
    [packagingStandardCostsRowsWithoutDescription],
  );

  const totalQuantity = useMemo(
    () => ({
      fmc1:
        Calculator.Common.SumList(packagingStandardCostsRowsWithoutDescription, 'fmc1quantity') ||
        0,
      fmc2:
        Calculator.Common.SumList(packagingStandardCostsRowsWithoutDescription, 'fmc2quantity') ||
        0,
    }),
    [packagingStandardCostsRowsWithoutDescription],
  );

  function changeDescription(key: string, newDescription: string) {
    setDescriptions((curr) => ({ ...curr, [key]: newDescription }));
  }

  function changeQuantity(key: string, fmcRegion: RegionFMC, newQuantity: number) {
    setQuantities((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newQuantity } }));
  }

  function changeCost(key: string, fmcRegion: RegionFMC, newCost: number) {
    setManualCosts((curr) => ({ ...curr, [key]: { ...curr[key], [fmcRegion]: newCost } }));
  }

  function addRow() {
    const key = getFreshId();

    setKeys((curr) => curr.concat({ key, custom: true }));

    setDescriptions((curr) => ({ ...curr, [key]: '' }));
    setQuantities((curr) => ({ ...curr, [key]: { FMC1: 0, FMC2: 0 } }));
    setManualCosts((curr) => ({ ...curr, [key]: { FMC1: 0, FMC2: 0 } }));
  }

  function deleteRow(key: string) {
    setKeys((curr) => curr.filter((k) => k.key !== key));

    setDescriptions((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
    setQuantities((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
    setManualCosts((curr) => {
      const newCurr = { ...curr };
      delete curr[key];
      return newCurr;
    });
  }

  useEffect(() => {
    clearLocalMessages(/packaging-standard-cost.*/);

    packagingStandardCostsRowsWithoutDescription.forEach((psc) => {
      psc.report
        .filter((report) => report.isError)
        .forEach((report) => {
          addLocalMessage({
            id: `packaging-standard-cost_error_${psc.key}`,
            message: `${descriptions[psc.key]}: ${report.shortDescription}`,
          });
        });
    });
  }, [
    packagingStandardCostsRowsWithoutDescription,
    clearLocalMessages,
    addLocalMessage,
    descriptions,
  ]);

  function onPaste(updates: PasteChanges<PackagingStandardCostsRow>) {
    updates.forEach(({ row, changes }) => {
      if (typeof changes.description === 'string') changeDescription(row.key, changes.description);
      if (typeof changes.fmc1quantity === 'number')
        changeQuantity(row.key, 'FMC1', changes.fmc1quantity);
      if (typeof changes.fmc2quantity === 'number')
        changeQuantity(row.key, 'FMC2', changes.fmc2quantity);
      if (typeof changes.fmc1cost === 'number') changeCost(row.key, 'FMC1', changes.fmc1cost);
      if (typeof changes.fmc2cost === 'number') changeCost(row.key, 'FMC2', changes.fmc2cost);
    });
  }

  const reset = useCallback(() => {
    hasRunInitialSetup.current = false;
  }, []);

  function importRows(rows: DBPackagingStandardCost[]) {
    if (rows.length === 0) {
      showInfoToast('No packaging standard costs to import');
      return;
    }

    updateRowsFromCostInputs(
      'FMC1',
      rows.map((r) => ({
        description: r.description,
        packingKey: r.packing_key,
        cost: r.fmc1_cost,
        custom: r.custom,
        quantity: r.fmc1_quantity,
      })),
    );
    updateRowsFromCostInputs(
      'FMC2',
      rows.map((r) => ({
        description: r.description,
        packingKey: r.packing_key,
        cost: r.fmc2_cost,
        custom: r.custom,
        quantity: r.fmc2_quantity,
      })),
    );
  }

  return {
    packagingStandardCostsRows,
    reinitialize,
    reset,
    handlers: {
      changeDescription,
      changeQuantity,
      changeCost,
      addRow,
      deleteRow,
      onPaste,
      importRows,
    },
    totals: { cost: totalCost, quantity: totalQuantity },
    messages: localMessages,
  };
}
