import { DBElemCost } from '@core/schemas/db/schema.db.elements';
import { Calculator } from '../calculator';
import { round5 } from '../calculator.util';
import { CostComponent } from '@core/types/types.costComponent';
import { CostComponentCost } from '../calculator.types';

export function calculate_CC_ElementsScrap_sum(input: {
  elements: Pick<DBElemCost, 'total_cost' | 'cost_comp'>[];
  scrapRatePercentEU: number | undefined;
  scrapRatePercentUS: number | undefined;
  options?: { noRounding?: boolean };
}): CostComponentCost {
  const { elements, scrapRatePercentEU, scrapRatePercentUS, options } = input;

  const costLEGOElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.LEGOElements,
  );
  const costPurchasedElements = Calculator.Elements.CC_Elements.Sum(
    elements,
    CostComponent.PurchasedElements,
  );

  const fmc1cost = !scrapRatePercentEU
    ? 0
    : Calculator.Common.QuotientStrict(scrapRatePercentEU, 100) *
      (costLEGOElements + costPurchasedElements);

  const fmc2cost = !scrapRatePercentUS
    ? 0
    : Calculator.Common.QuotientStrict(scrapRatePercentUS, 100) *
      (costLEGOElements + costPurchasedElements);

  if (options?.noRounding) {
    return { fmc1cost, fmc2cost };
  }

  return { fmc1cost: round5(fmc1cost), fmc2cost: round5(fmc2cost) };
}
