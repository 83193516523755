import { AMGroupNameEnum } from '@core/types/types.amGroupName';
import { Email } from 'read-excel-file';
import { z } from 'zod';

// RegionFMC
export const RegionFMCEnum = z.enum(['FMC1', 'FMC2']);
export type RegionFMC = z.infer<typeof RegionFMCEnum>;

// RegionFMCRecord
export const RegionFMCRecordSchema = z.object({
  FMC1: z.number(),
  FMC2: z.number(),
});

// Region
export const RegionEnum = z.enum(['EU', 'US']);
export type Region = z.infer<typeof RegionEnum>;

// Report RevisionsMode
export const RevisionsModeEnum = z.enum(['LATEST', 'LATEST_APPROVED', 'ALL']);
export type RevisionsMode = z.infer<typeof RevisionsModeEnum>;

// ViewName
export const ViewNameEnum = z.enum(['bi', 'elements', 'packaging', 'finalcosts']);
export type ViewName = z.infer<typeof ViewNameEnum>;

// ADGroup
export const ADGroupSchema = z.object({
  id: z.string(),
  name: z.nativeEnum(AMGroupNameEnum),
  description: z.string(),
});
export type ADGroup = z.infer<typeof ADGroupSchema>;

// UserWithGroups
export type UserWithGroups = z.infer<typeof UserWithGroupsSchema>;
export const UserWithGroupsSchema = z.object({
  email: z.string(),
  name: z.string(),
  groups: z.array(ADGroupSchema),
});

// ADGroupMember
export const ADGroupMemberSchema = z.object({
  email: z.string(),
  name: z.string(),
});
export type ADGroupMember = z.infer<typeof ADGroupMemberSchema>;

// ADGroupWithMembers
export const ADGroupWithMembersSchema = ADGroupSchema.extend({
  members: z.array(ADGroupMemberSchema),
});
export type ADGroupWithMembers = z.infer<typeof ADGroupWithMembersSchema>;

export enum RevisionErrorOrigin {
  Empty = '',
  BICost = 'BI_COST',
  PackingStandardCost = 'PACKING_STANDARD_COST',
  PackingNonstandardCost = 'PACKING_NONSTANDARD_COST',
  PackingCost = 'PACKING_COST',
  PackagingStandardCost = 'PACKAGING_STANDARD_COST',
  PackagingNonstandardCost = 'PACKAGING_NONSTANDARD_COST',
}

// Revision error
export const RevisionErrorSchema = z.object({
  origin: z.nativeEnum(RevisionErrorOrigin),
  id: z.string(),
  message: z.string(),
});
export type RevisionError = z.infer<typeof RevisionErrorSchema>;
