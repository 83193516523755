import { PK } from '@core/types/types.pk';
import { Calculator, useCacheOrFetchQuery } from '../calculator';
import { Calculate_FetchQuery } from '../calculator.types';
import { DBPackagingRate } from '@core/schemas/db/schema.db.packaging';
import { PackSource } from '@core/types/types.packaging';

export async function calculate_packaging_isStandard(props: {
  input: {
    year: number | undefined;
    packagingSize: number;
    sourceEu: PackSource;
    sourceUs: PackSource;
  };
  cache?: { packagingRates?: DBPackagingRate[] };
  query?: { query?: Calculate_FetchQuery };
}) {
  const { input, cache, query } = props;

  if (input.year === undefined) {
    return false;
  }

  const packagingRates = await useCacheOrFetchQuery({
    cachedItems: cache?.packagingRates,
    fetchQuery: query?.query,
    fetchQueryArgs: {
      pk: PK.PackagingRate,
      query: Calculator.Pack.Packaging.IsStandard.Filter.PackagingRates({
        year: input.year,
        packagingSize: input.packagingSize,
      }),
    },
    dataTypeDescription: 'packaging rates',
  });

  const fmc1packagingRates = packagingRates.filter((r) => r.source === input.sourceEu);
  const fmc2packagingRates = packagingRates.filter((r) => r.source === input.sourceUs);

  return fmc1packagingRates.length > 0 && fmc2packagingRates.length > 0;
}
