import { useContext, useEffect, useMemo } from 'react';
import {
  ConfigContext,
  LoadingOverlayContext,
  ProductIndexContext,
  UserDataContext,
} from '@frontend/common/lib/contexts';
import { BICostOverview } from './BiCostOverview';
import {
  accessIsReadOnly,
  isRevisionReadOnly,
  showErrorToast,
  showInfoToast,
} from '@frontend/common/lib/functions';
import { NCAttachments } from '@frontend/common/components/NCAttachments';
import { GeneralPage } from '@frontend/common/components/GeneralPage';
import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { BIProductHeader } from './BiProductHeader';
import { BICosts } from './BiCosts';
import { useBooksAndRates } from '@frontend/bi/logic/useBooksAndRates';
import { useBiStatus } from '../logic/useBiStatus';
import { PK } from '@core/types/types.pk';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  BIGetEndpoint,
  BINewGetEndpoint,
  BISaveEndpoint,
  BISaveEndpointInput,
} from '@core/schemas/endpoint/schema.endpoint.bi';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import { checkApproveBI } from '@core/checks/check.approve.bi';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';

export function BIPage() {
  const { setRevision, clearProductIndex, setSuggestedProductId, getProductIndex } =
    useContext(ProductIndexContext);
  const rev = getProductIndex().revision;
  const {
    data,
    loading,
    statusCode,
    error,
    queryFunction: refresh,
  } = useEndpoint({
    endpoint: BIGetEndpoint,
    input: {
      productId: convertProductIdTo8digits(getProductIndex().productId || 0),
      rev: rev === 'new' ? 1 : rev,
    },
    condition: !!getProductIndex().productId && rev !== 'new',
    errorHandling: { disable: true },
  });

  const {
    data: dataNew,
    loading: loadingNew,
    queryFunction: refreshNew,
  } = useEndpoint({
    endpoint: BINewGetEndpoint,
    input: { productId: convertProductIdTo8digits(getProductIndex().productId || 0) },
    condition: !!getProductIndex().productId && rev === 'new',
    errorHandling: {
      header: 'Fetching initial building instruction data',
      handlers: {
        '400': (message) => {
          const revisionExists = message === 'A revision already exists for this product';
          if (revisionExists) {
            setRevision(undefined);
          }
          (revisionExists ? showInfoToast : showErrorToast)(
            'Fetching initial building instructions data',
            message,
          );
        },
      },
    },
  });
  const { setIsLoading } = useContext(LoadingOverlayContext);
  const { email, groupNames } = useContext(UserDataContext);
  const { config } = useContext(ConfigContext);

  useEffect(() => {
    if (error && getProductIndex().productId && getProductIndex().revision !== 'new') {
      if (statusCode === 404) {
        setSuggestedProductId(getProductIndex().productId);
        showInfoToast(
          'Revision does not exist',
          getProductIndex().revision
            ? `Product does not have a revision ${getProductIndex().revision}`
            : 'Product does not have a BI revision',
        );
        clearProductIndex();
      } else {
        showErrorToast('Fetching building instructions data', error.error);
      }
    }
  }, [error, statusCode, clearProductIndex, setSuggestedProductId, getProductIndex]);

  const relevantData = useMemo(() => data || dataNew, [data, dataNew]);

  const readOnly = useMemo(
    () =>
      isRevisionReadOnly({ prodHead: relevantData?.prodHead, email, year: config.fmc_active_year }),
    [email, config.fmc_active_year, relevantData?.prodHead],
  );

  const { books, isFetchingRate, handlers, messages } = useBooksAndRates({
    payloadBiCosts: relevantData?.biCosts,
    useQrCode: !!relevantData?.prodHead.bi_qr_code,
    fmcYear: relevantData?.prodHead.year,
    readOnly,
  });

  const { biStatus, updateBiStatus } = useBiStatus();

  useEffect(() => {
    updateBiStatus(relevantData?.prodHead.bi_status || 0);
  }, [relevantData, updateBiStatus]);

  useEffect(() => {
    setIsLoading(loading || loadingNew);
    return () => setIsLoading(false);
  }, [loading, loadingNew, setIsLoading]);

  function handleRevisionSelect(revision: number) {
    if (!getProductIndex().productId) return;

    setRevision(revision);
  }

  async function preSaveCheck(): Promise<BISaveEndpointInput & { revision: number }> {
    if (!relevantData) {
      throw new Error('Application error: Data is missing');
    }

    return {
      productId: relevantData.prodHead.product_id,
      revision: relevantData.prodHead.revision,
      books,
      biStatus,
    };
  }

  const productId = getProductIndex().productId;

  async function preApproveCheck() {
    checkApproveBI({ biStatus, books });
  }

  const readOnlyAccess = useMemo(() => accessIsReadOnly(groupNames, PK.ProdHeadBI), [groupNames]);

  return (
    <GeneralPage
      title="Building instructions"
      loading={loading || loadingNew}
      refresh={refresh}
      refreshNew={refreshNew}
      prodHead={relevantData?.prodHead}
      description={relevantData?.description}
      maxRevision={relevantData?.maxRevision}
      onSave={{ preSaveCheck, save }}
      preApproveCheck={preApproveCheck}
      pk={PK.ProdHeadBI}
    >
      {relevantData && (
        <NCAccordion
          sections={[
            {
              title: 'Product header',
              content: (
                <BIProductHeader
                  readOnly={readOnlyAccess}
                  prodHead={relevantData.prodHead}
                  biStatus={biStatus}
                  erectCode={relevantData.erectCode}
                  maxRevision={relevantData.maxRevision}
                  onRevisionSelect={handleRevisionSelect}
                  onBiStatusChange={updateBiStatus}
                />
              ),
              noBottomMargin: true,
            },
            {
              title: 'Product costs',
              content: <BICostOverview productCosts={relevantData?.productCosts} biCosts={books} />,
            },
            {
              title: 'Building instructions costs',
              content: (
                <BICosts
                  productId={relevantData.prodHead.product_id}
                  fmcYear={relevantData.prodHead.year}
                  readOnly={readOnly || readOnlyAccess}
                  books={books}
                  handlers={handlers}
                  hasQrCode={!!relevantData.prodHead.bi_qr_code}
                  isFetchingRate={isFetchingRate}
                  messages={messages}
                />
              ),
            },
            {
              title: 'Attachments',
              content: productId && (
                <NCAttachments
                  view="bi"
                  productId={productId}
                  readOnly={readOnly || readOnlyAccess}
                />
              ),
            },
          ]}
        />
      )}
    </GeneralPage>
  );
}

function save(verifiedPayload: BISaveEndpointInput) {
  return callEndpoint({
    endpoint: BISaveEndpoint,
    input: verifiedPayload,
    errorHandling: { disable: true },
  });
}
