import { LEGOLogo, Text, Navbar, Spacer } from '@lego/klik-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { LF_LAST_VISITED, PageRoute, pageRoutes } from '@frontend/common/lib/definitions';
import { ProfileItem } from './ProfileItem';
import * as localforage from 'localforage';
import { isPathAccesible, isProd } from '../lib/functions';
import { useContext } from 'react';
import { UserDataContext } from '../lib/contexts';
import { CircleSpinner } from './CircleSpinner';

export function NavigationBar() {
  const { groupNames, isRefreshing } = useContext(UserDataContext);

  const location = useLocation();
  const navigate = useNavigate();

  function doNavigate(to: PageRoute) {
    navigate(to);
    localforage.setItem(LF_LAST_VISITED, to);
  }

  return (
    <Navbar>
      <Navbar.Brand onClick={() => doNavigate(pageRoutes.Elements)}>
        <LEGOLogo mr="24px" />
        <Text>
          Novelty Costing{' '}
          {!isProd() ? `(${STAGE}${REFERENCE_NUMBER === 0 ? '' : `, ${REFERENCE_NUMBER}`})` : ''}
        </Text>
      </Navbar.Brand>
      {groupNames.length === 0 && isRefreshing && (
        <div style={{ marginLeft: 24, display: 'flex', alignItems: 'center' }}>
          <CircleSpinner />
        </div>
      )}
      <Navbar.Segment display={{ base: 'none', lg: 'flex' }}>
        {Object.entries(pageRoutes)
          .filter(([name]) => name !== 'WelcomePage')
          .filter(([, path]) => typeof path !== 'string' || isPathAccesible(path, groupNames))
          .map(([name, path]) =>
            typeof path === 'string' ? (
              <Navbar.Item
                key={path}
                isActive={location.pathname === path}
                onClick={() => doNavigate(path)}
              >
                {name}
              </Navbar.Item>
            ) : groupNames.length === 0 ? null : (
              <Navbar.Item
                key={name}
                hasMenu={true}
                isActive={Object.values(path).includes(location.pathname as PageRoute)}
                icon={MdKeyboardArrowDown}
                title={name}
              >
                {Object.entries(path)
                  .filter(([, path]) => isPathAccesible(path, groupNames))
                  .map(([name, path]) => (
                    <Navbar.Option
                      key={path}
                      isOpen={location.pathname === path}
                      onClick={() => doNavigate(path)}
                    >
                      {name}
                    </Navbar.Option>
                  ))}
              </Navbar.Item>
            ),
          )}
      </Navbar.Segment>
      <Spacer />
      <Navbar.Segment display={{ base: 'none', md: 'flex' }}>
        <ProfileItem />
      </Navbar.Segment>
      <Navbar.Burger display={{ base: 'block', lg: 'none' }} />
    </Navbar>
  );
}
