import { DBProdHeadPack } from '@core/schemas/db/schema.db.packaging';
import { OmitDBRecord } from '@core/schemas/db/schema.db.common';
import { now } from '@core/util/util.timestamp';
import { PackSource } from '@core/types/types.packaging';

export const ProdHeadPackExample: OmitDBRecord<DBProdHeadPack>[] = [
  {
    product_id: 1,
    revision: 1,
    year: 1,
    created_by: 'abc@def.com',
    created_timestamp: now(),
    changed_by: undefined,
    changed_timestamp: undefined,
    approved_by: undefined,
    approved_timestamp: undefined,
    rejected_by: undefined,
    rejected_timestamp: undefined,
    estimation_code: undefined,
    alternative_code: undefined,
    rejection_lock: false,
    packaging_size: 123,
    box_filling_degree_pct: 10,
    source_eu: PackSource.TruvantLELine,
    source_us: PackSource.LEGOTechnic,
    packing_region_eu: 'EU',
    packing_region_us: 'US',
    sales_region_eu: 'EU',
    sales_region_us: 'US',
    embossing: true,
    hotfoil: true,
    uv_lacquer: false,
    partial_uv_lacquer: false,
    window_cutout: false,
    window_foil: false,
    model_bag: true,
    currency: 'DKK',
    note: 'abc def',
    errors: JSON.stringify([]),
  },
];
