import { useContext, useEffect, useMemo } from 'react';
import { GeneralPage } from '@frontend/common/components/GeneralPage';
import { NCAccordion } from '@frontend/common/components/NCAccordion';
import { NCAttachments } from '@frontend/common/components/NCAttachments';
import { Table } from '@frontend/table/table';
import {
  LoadingOverlayContext,
  ProductIndexContext,
  UserDataContext,
} from '@frontend/common/lib/contexts';
import { ElementsCostOverview } from '@frontend/elements/components/ElementsCostOverview';
import { ElementCosts } from '@frontend/elements/components/ElementsCosts';
import { ElementsProductHeader } from '@frontend/elements/components/ElementsProductHeader';
import { elementErrorsColumns } from '../elements.columns';
import { PK } from '@core/types/types.pk';
import { useEndpoint } from '@frontend/common/lib/hooks/useEndpoint';
import {
  ElementsCalculateEndpoint,
  ElementsGetEndpoint,
} from '@core/schemas/endpoint/schema.endpoint.elements';
import { callEndpoint } from '@frontend/common/lib/callEndpoint';
import {
  accessIsReadOnly,
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from '@frontend/common/lib/functions';
import { useMessages } from '@frontend/common/lib/hooks/useMessages';
import { convertProductIdTo8digits } from '@core/util/util.convertProductIdTo8digits';
import { checkApproveElements } from '@core/checks/check.approve.elements';

export function ElementsPage() {
  const {
    setRevision,
    getProductIndex,
    clearProductIndex,
    setSuggestedProductId,
    setProductIndex,
  } = useContext(ProductIndexContext);
  const rev = getProductIndex().revision;
  const {
    data,
    loading,
    error,
    statusCode,
    queryFunction: refresh,
  } = useEndpoint({
    endpoint: ElementsGetEndpoint,
    input: {
      productId: convertProductIdTo8digits(getProductIndex().productId || 0),
      rev: rev === 'new' ? 1 : rev,
    },
    condition: !!getProductIndex().productId && rev !== 'new',
    errorHandling: { disable: true },
  });
  const { setIsLoading } = useContext(LoadingOverlayContext);
  const { groupNames } = useContext(UserDataContext);
  const { messages, clearMessages, addMessage } = useMessages();

  useEffect(() => {
    if (error && getProductIndex().productId && getProductIndex().revision !== 'new') {
      if (statusCode === 404) {
        setSuggestedProductId(getProductIndex().productId);
        showInfoToast(
          'Revision does not exist',
          getProductIndex().revision
            ? `Product does not have a revision ${getProductIndex().revision}`
            : 'Product does not have an Elements revision',
        );
        clearProductIndex();
      } else {
        showErrorToast('Fetching elements data', error.error);
      }
    }
  }, [error, statusCode, clearProductIndex, setSuggestedProductId, getProductIndex]);

  useEffect(() => {
    if (data) {
      if (data.elementErrors.length > 0) {
        addMessage({
          id: 'calculation-error',
          message: `Revision contains ${data.elementErrors.length} calculation error${
            data.elementErrors.length === 1 ? '' : 's'
          }`,
        });
      } else {
        clearMessages();
      }
    }
  }, [data, addMessage, clearMessages]);

  useEffect(() => {
    setIsLoading(loading);
    return () => setIsLoading(false);
  }, [loading, setIsLoading]);

  function handleRevisionSelect(revision: number) {
    if (!getProductIndex().productId) return;

    setRevision(revision);
  }

  async function calculate(productId: number) {
    const [err, data] = await callEndpoint({
      endpoint: ElementsCalculateEndpoint,
      input: { productId },
      errorHandling: {
        header: 'Calculating new element revision',
      },
    });

    if (err) {
      return false;
    }

    if (data.noOpReason) {
      showInfoToast('Calculating new element revision', `No updates. ${data.noOpReason}`);
      return false;
    } else {
      showSuccessToast('Calculated new element revision', 'New revision created');
      setProductIndex(productId, data.revisionNumber);
      return true;
    }
  }

  const elementsErrorsWithDescription = useMemo(
    () =>
      (data?.elementErrors || []).map((e) => ({
        ...e,
        materialDescription:
          (data?.elementCosts || []).find((c) => c.material === e.material)?.description || '',
      })),
    [data],
  );

  const productId = getProductIndex().productId;

  async function preApproveCheck() {
    checkApproveElements({ elemErrors: data?.elementErrors || [] });
  }

  const readOnlyAccess = useMemo(() => accessIsReadOnly(groupNames, PK.ProdHeadBI), [groupNames]);

  return (
    <GeneralPage
      title="Elements"
      loading={loading}
      prodHead={data?.prodHead}
      description={data?.description}
      maxRevision={data?.maxRevision}
      refresh={refresh}
      preApproveCheck={preApproveCheck}
      messages={messages}
      onCreate={async (pid) => await calculate(pid)}
      onCalculate={data ? async () => await calculate(data.prodHead.product_id) : undefined}
      pk={PK.ProdHeadElem}
    >
      {data && (
        <NCAccordion
          sections={[
            {
              title: 'Product header',
              content: (
                <ElementsProductHeader
                  onRevisionSelect={handleRevisionSelect}
                  maxRevision={data.maxRevision}
                  prodHead={data.prodHead}
                />
              ),
              noBottomMargin: true,
            },
            {
              title: 'Product costs',
              content: (
                <ElementsCostOverview
                  elementCosts={data.elementCosts}
                  productCosts={data.productCosts}
                />
              ),
            },
            {
              title: 'Error log',
              content: (
                <Table
                  id="elements_error-log"
                  rows={elementsErrorsWithDescription}
                  rowKey={(r) => r.SK + r.material}
                  columns={elementErrorsColumns}
                  removeInfoText
                  removeSearch
                  itemsPerPage={15}
                  fixedHeight={430}
                />
              ),
              hide: data.elementErrors.length === 0,
            },

            {
              title: 'Element costs',
              content: (
                <ElementCosts elementCosts={data.elementCosts} elementErrors={data.elementErrors} />
              ),
            },
            {
              title: 'Attachments',
              content: productId && (
                <NCAttachments productId={productId} view="elements" readOnly={readOnlyAccess} />
              ),
            },
          ]}
        />
      )}
    </GeneralPage>
  );
}
