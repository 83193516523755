import { PK } from '@core/types/types.pk';
import { createDescription } from '@core/util/util.schemaPropDescription';
import { z } from 'zod';
import { IsoDateSchema } from '../schema.util';
import { DBProdHeadElem } from './schema.db.elements';
import { DBProdHeadPack } from './schema.db.packaging';
import { DBProdHeadBI } from './schema.db.bi';
import { DBProdHeadFC } from './schema.db.prod';

export type DBProdHead = DBProdHeadElem | DBProdHeadPack | DBProdHeadBI | DBProdHeadFC;

/**
 * DBRecord
 */
export const DBRecordSchema = z.object({
  PK: z.nativeEnum(PK),
  SK: z.string(),
});
export type DBRecord = z.infer<typeof DBRecordSchema>;
export type OmitDBRecord<Record> = Omit<Record, keyof DBRecord>;

/**
 * DBElementsScrapRate
 */
export const DBElementsScrapRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.ElementsScrapRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  type: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  description: z.string(),
  scrap_rate_percent: z.number(),
  disabled: z.boolean(),
});
export type DBElementsScrapRate = z.infer<typeof DBElementsScrapRateSchema>;

/**
 * DBOHRate
 */
export const DBOHRateSchema = DBRecordSchema.extend({
  PK: z.literal(PK.OHRate),
  year: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true })),
  type: z
    .number()
    .int()
    .describe(createDescription({ isPartOfKey: true, supportLov: true })),
  description: z.string(),
  oh_rate_percent: z.number(),
  planning_oh_finished_goods_dkk: z.number(),
  planning_oh_prepacks_dkk: z.number(),
  disabled: z.boolean(),
});
export type DBOHRate = z.infer<typeof DBOHRateSchema>;

/**
 * DBAMLog
 */
export enum AMLogActionType {
  Add = 'add',
  Remove = 'remove',
}
export const DBAMLogSchema = DBRecordSchema.extend({
  PK: z.literal(PK.AMLog),
  id: z.string().describe(createDescription({ isPartOfKey: true })),
  group_id: z.string(),
  timestamp: IsoDateSchema,
  action_by: z.string(),
  action_on: z.string(),
  action_type: z.nativeEnum(AMLogActionType).describe(createDescription({ supportLov: true })),
});
export type DBAMLog = z.infer<typeof DBAMLogSchema>;
